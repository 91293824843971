<template>
  <div class="pa-3">
    <div class="mb-3">
      <v-text-field
              v-model="reply.replyContent"
              variant="underlined"
              hide-details
              placeholder="Reply.."
              append-inner-icon="mdi-plus"
              clearable
              @click:append-inner="registerReply"
      />
    </div>
    <div>
      <div v-for="item in replies" :key="`reply-${item.idx}`" class="mt-1 mb-3">
        <div class="d-flex text-13 pa-1 align-center" :style="{ backgroundColor: '#f6f6f6' }">
          <div>
            <span class="font-weight-bold text-grey-darken-2">
              {{ item.username }}
            </span>
            <span v-if="board && item.usersId === board.usersId" class="text-11">
              (작성자)
            </span>
          </div>
          <v-spacer />
          <span class="text-grey-darken-1">
            {{ $parseDate(item.date, 'yyyy.MM.DD HH:mm') }}
          </span>
          <template v-if="item.owner"><span class="ml-1">·</span><v-btn color="transparent" flat size="small" class="px-1 pb-0 min-width-0 min-height-0 h-100" @click="deleteReply(item.idx)">삭제</v-btn></template>
        </div>
        <v-divider />
        <div class="px-1 py-1 text-13 font-weight-light">
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplyLayer',
  props: ['board'],
  data () {
    return {
      replies: [],
      reply: {
        replyContent: ''
      }
    }
  },
  beforeMount () {
    if (this.board) {
      console.log(this.board)
      this.getReplies()
    }
  },
  methods: {
    async getReplies () {
      await this.$axios.get(`/board/${this.board.idx}/reply/list`).then(res => {
        this.replies = res.data
        this.initReply()
      })
    },
    async registerReply () {
      if (this.reply && this.reply.replyContent) {
        this.reply.board = {
          idx: this.board.idx
        }
        await this.$axios.put(`/board/${this.board.idx}/reply`, this.reply).then(() => {
          this.getReplies()
        }).catch(() => {
          alert('댓글 등록 중 오류가 발생하였습니다.')
        })
      }
    },
    async deleteReply (idx) {
      if (confirm('삭제 후 복구가 불가능합니다.\n댓글을 삭제하시겠습니까?')) {
        await this.$axios.delete(`/board/reply?idx=${idx}`).then(() => {
          this.getReplies()
        }).catch(() => {
          alert('댓글 삭제 중 오류가 발생하였습니다.')
        })
      }
    },
    initReply () {
      this.reply = {
        replyContent: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
