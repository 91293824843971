<template>
  <v-card class="px-0 no-scroll">
    <v-card-title class="d-flex position-fixed w-100 bg-white" :style="{ left: 0, top: 0, zIndex: 1, height: '50px' }">
      <div class="w-75 text-14">
        {{ selectedImage.imageName }}
      </div>
      <div class="w-25 text-right">
        <v-btn size="xs" flat @click="close">
              <span class="text-12">
                Close
              </span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="pa-0" :style="{ marginTop: '50px' }">
      <v-img :src="selectedImage.imageSrc" :lazy-src="selectedImage.imageSrc" :style="{ maxHeight: '700px' }"
             width="100%" cover/>
    </v-card-text>
    <v-card-text class="px-0 d-flex flex-wrap">
      <v-row class="w-100">
        <v-col cols="12" md="4" class="d-flex justify-start align-center w-100 ps-7 text-14 font-weight-bold">
          {{ selectedImage.imageName }}
        </v-col>
        <v-col v-if="selectedImage.usedProductDtos" cols="12" md="8" class="d-flex justify-end w-100">
          <v-tooltip v-for="(item, i) in selectedImage.usedProductDtos" :key="'product-detail-' + i"
                     :text="item.productName" location="bottom">
            <template #activator="{ props }">
              <div v-bind="props" class="product-icon mx-2" @click="openProductDetail(item)">
                <v-img :src="item.imageSrc" :lazy-src="item.imageSrc" cover/>
              </div>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider class="mt-3"/>
    </v-card-text>
    <v-card-text v-if="selectedImage.videoSrc" class="pa-0">
      <v-dialog
              v-model="showVideo"
              persistent
              max-width="800px"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" class="w-100 pb-1" rounded="0" elevation="0" flat>
            영상보기
          </v-btn>
        </template>
        <v-card class="pa-0">
          <v-card-title class="text-right">
            <div class="w-100 text-right">
              <v-btn size="xs" flat @click="this.showVideo = false">
              <span class="text-12">
                Close
              </span>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="pa-0">
            <video-player
                    :src="selectedImage.videoSrc"
                    :poster="selectedImage.imageSrc"
                    controls
                    :loop="false"
                    aspect-ratio="16:9"
                    muted
                    not-supported-message="This video not supported."
                    preload="auto"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-dialog v-model="showProductDetail" v-if="selectedProduct" :max-width="$isMobile() ? '100%' : 800"
              :fullscreen="$isMobile()" transition="dialog-top-transition">
      <products-detail-card :selected-product="selectedProduct" @close-dialog="closeProductDetail"/>
    </v-dialog>
    <v-card-text class="pa-0">
      <div v-if="selectedImage.instagramLink" class="text-right px-5 cursor-pointer" @click="openInstagramLink">
        <div class="d-inline-block">
          <v-img :src="getSnsIconStyle" :lazy-src="getSnsIconStyle" class="mr-2" width="20px"
                 :style="{ position: 'relative', top: '5px' }"/>
        </div>
        <span>
          {{ getSnsName }}
        </span>
      </div>
      <div class="px-5 py-3 image-content" v-html="selectedImage.imageContent"/>
    </v-card-text>
    <v-card-actions/>
  </v-card>
</template>

<script>
import ProductsDetailCard from '@/components/products/ProductsDetailCard.vue'
import { VideoPlayer } from '@videojs-player/vue'

export default {
  name: 'StylesDetailCard',
  components: {
    VideoPlayer,
    ProductsDetailCard
  },
  props: ['selectedImage'],
  data () {
    return {
      showVideo: false,
      showProductDetail: false,
      selectedProduct: null
    }
  },
  computed: {
    getSnsIconStyle () {
      if (this.selectedImage) {
        const link = this.selectedImage.instagramLink
        if (link.indexOf('facebook') > -1) {
          return require('@/assets/images/icon/facebook_icon.png')
        } else if (link.indexOf('youtu.be') > -1 || link.indexOf('youtube') > -1) {
          return require('@/assets/images/icon/youtube_icon.png')
        } else if (link.indexOf('instagram') > -1) {
          return require('@/assets/images/icon/instagram_icon.png')
        } else {
          return require('@/assets/images/icon/url_icon.png')
        }
      } else {
        return null
      }
    },
    getSnsName () {
      if (this.selectedImage) {
        const link = this.selectedImage.instagramLink
        if (link.indexOf('facebook') > -1) {
          return 'Facebook'
        } else if (link.indexOf('youtu.be') > -1 || link.indexOf('youtube') > -1) {
          return 'Youtube'
        } else if (link.indexOf('instagram') > -1) {
          return 'Instagram'
        } else {
          return '외부링크 바로가기'
        }
      } else {
        return null
      }
    }
  },
  methods: {
    openProductDetail (product) {
      this.selectedProduct = product
      this.showProductDetail = true
    },
    closeProductDetail () {
      this.showProductDetail = false
      setTimeout(() => {
        this.selectedProduct = null
      }, 300)
    },
    openInstagramLink () {
      window.open(this.selectedImage.instagramLink, '_blank')
    },
    close () {
      this.$emit('close-dialog')
    }
  }

}
</script>

<style scoped lang="scss">
.product-icon {
  border-radius: 100%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 6px 0px #eee;
}

.v-card-text:deep(.image-content) {
  font-size: 14px;

  img {
    max-width: 100%;
  }
}
</style>
