<template>
  <div class="h-100">
    <v-row class="h-100 overflow-hidden">
      <v-col lg="2" md="3" cols="12" class="py-0 pt-md-3 d-none h-100 d-md-block">
        <products-left-menu :product-types="productTypes"/>
      </v-col>
      <v-col lg="10" md="9" cols="12" class="py-0 pt-md-3 pl-md-0 pl-3 h-100">
        <div class="pr-3 py-1 pt-md-3 h-100 pl-3 pl-md-0">
          <v-sheet
                  height="100%"
                  rounded="lg"
                  class="pt-3 px-2 overflow-y-auto overflow-x-hidden no-scroll"
          >
            <v-row :style="{ height: '44px' }">
              <v-col cols="6" class="text-left py-0">
                <v-btn v-if="isShowPrev" flat size="large" rounded="0" @click="prevPage">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                  이전
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right py-0">
                <v-btn v-if="isShowNext" flat size="large" rounded="0" @click="nextPage">
                  다음
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="pagination && pagination.list.length > 0">
              <v-col v-for="(item, i) in pagination.list" :key="'style-image-' + i" xl="2" lg="2" md="3" sm="4" cols="6"
                     class="pa-1">
                <v-card elevation="0" class="mb-5 pa-0">
                  <v-card-text class="product-images pa-0">
                    <v-img
                            :src="item.imageSrc"
                            :lazy-src="item.imageSrc"
                            :aspect-ratio="9/16"
                            cover
                            max-height="230px"
                            @click="openProductDetail(item)"
                    />
                  </v-card-text>
                  <v-card-title class="px-1">
                    <h5 class="text-13 mb-0">
                      {{ item.productName }}
                    </h5>
                    <p class="text-11 lh-11">
                      {{ item.productPrice ? parseInt(item.productPrice).toLocaleString() + '원' : '-' }}
                    </p>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="py-0 pt-md-3 pl-md-0 pl-3">
                <div class="pr-3 py-1 pt-md-3 h-100 pl-3 pl-md-0 d-flex justify-center align-center flex-wrap position-relative">
                  <p class="w-100 text-center nanumgothic text-13">
                    검색된 제품이 존재하지 않습니다.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showProductDetail" v-if="selectedProduct" :max-width="$isMobile() ? '100%' : 800"
              :fullscreen="$isMobile()" transition="dialog-top-transition">
      <products-detail-card :selected-product="selectedProduct" @close-dialog="closeProductDetail"/>
    </v-dialog>
    <div :style="{ position: 'absolute', left: '20px', bottom: '20px' }" class="d-block d-md-none">
      <v-dialog v-model="showFilter" fullscreen>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="mdi-filter" color="grey-darken-4"/>
        </template>
        <template #default="{ isActive }">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="6">
                  <v-btn
                          flat
                          color="transparent"
                          icon
                          size="small"
                          @click="isActive.value = false"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <span class="text-14">
                    Products
                  </span>
                </v-col>
                <v-col cols="6" class="d-flex align-center justify-end">
                  <h5 class="text-14 font-weight-medium pr-3 lh-14">
                    제품 분류
                  </h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row class="px-1">
                <h5 class="text-14 mb-1 font-weight-medium">
                  기본 스타일
                </h5>
                <v-divider color="grey-darken-3 px-1"/>
              </v-row>
              <v-row class="mt-5">
                <v-col v-for="(item, i) in productTypes" :key="'left-menu-' + i" cols="6" class="pa-1">
                  <v-btn :to="item.link" @click="isActive.value = false" variant="outlined" class="w-100" color="grey"
                         rounded="0">
                    <span class="text-13 text-grey-darken-2">
                      {{ item.productTypeName }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ProductsDetailCard from '@/components/products/ProductsDetailCard.vue'
import ProductsLeftMenu from '@/components/products/ProductsLeftMenu.vue'

export default {
  name: 'ProductsView',
  components: {
    ProductsLeftMenu,
    ProductsDetailCard
  },
  data () {
    return {
      showFilter: false,
      pagination: {
        list: []
      },
      productTypes: [],
      showProductDetail: false,
      selectedProduct: null,
      pageNum: this.$route.params.pageNum ? parseInt(this.$route.params.pageNum) : 1,
      pageDataSize: this.$route.query.pageDataSize || 18,
      productUrl: '/products/1?productTypeIdx='
    }
  },
  computed: {
    productIdx () {
      return this.$route.query ? this.$route.query.productIdx : null
    },
    productTypeIdx () {
      return this.$route.query ? this.$route.query.productTypeIdx : null
    },
    isShowPrev () {
      if (this.pageNum !== 1) {
        return true
      } else {
        return false
      }
    },
    isShowNext () {
      return this.pageNum < this.pagination.maxPageNum
    },
    path () {
      return this.$route.fullPath
    }
  },
  watch: {
    productTypeIdx () {
      this.pageNum = 1
      this.getPagination(1)
    },
    pageNum () {
      this.$router.push({
        path: `/products/${this.pageNum}`,
        query: this.$route.query
      })
    },
    path () {
      this.getPagination(this.$route.params.pageNum)
    }
  },
  beforeMount () {
    this.getProductTypes()
    this.getPagination()
  },
  methods: {
    nextPage () {
      this.pageNum = this.pageNum + 1
    },
    prevPage () {
      this.pageNum = this.pageNum - 1
    },
    async getProductTypes () {
      await this.$axios.get('/product/type/list').then(res => {
        this.productTypes = res.data
        let count = 0
        for (const p of this.productTypes) {
          if (!this.productTypeIdx && count === 0) {
            this.$router.push(this.productUrl + p.idx)
          }
          p.link = this.productUrl + p.idx
          count++
        }
      })
    },
    async getPagination (pageNum) {
      const params = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize,
        idx: this.productIdx
      }
      if (this.productTypeIdx) {
        params.productTypeIdx = this.productTypeIdx
      }
      await this.$axios.get('/product/list', { params: params }).then(res => {
        this.pagination = res.data
      })
    },
    openProductDetail (product) {
      this.selectedProduct = product
      this.showProductDetail = true
    },
    closeProductDetail () {
      this.showProductDetail = false
      setTimeout(() => {
        this.selectedProduct = null
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.product-images {
  border: 1px solid #eee;
}
</style>
