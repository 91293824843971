<template>
  <v-app :style="{
    backgroundColor: bgColor
  }">
    <v-alert
            v-if="alerts.show"
            :type="alerts.type"
            closable
            :style="{
          zIndex: 9999,
          position: 'fixed',
          right: '0',
          top: '10px',
        }"
    >
      {{ alerts.text }}
    </v-alert>
    <router-view/>
  </v-app>
</template>

<style lang="scss">
@import 'assets/css/styles.scss';

html, body {
  height: 100%;
  overflow: hidden !important;
}

.v-application {
  background-color: #c0a486;
  height: 100%;
  position: fixed;
  width: 100%;
  max-width: 1600px;
  box-shadow: 0 0 8px 2px #595959;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  color: #2c3e50;
  height: 100%;
  background-image: linear-gradient(
                  rgba(255, 255, 255, 0.3),
                  rgba(255, 255, 255, 0.3)
  ), url("../public/background/main_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.clear-font {
  text-shadow: 0px 0px white;
}

.text-11 {
  font-size: 11px;
}

.lh-11 {
  line-height: 11px;
}

.text-12 {
  font-size: 12px;
}

.lh-12 {
  line-height: 12px;
}

.text-13 {
  font-size: 13px;
}

.lh-13 {
  line-height: 13px;
}

.text-14 {
  font-size: 14px;
}

.lh-14 {
  line-height: 14px;
}

.text-15 {
  font-size: 15px;
}

.lh-15 {
  line-height: 15px;
}

.text-16 {
  font-size: 16px;
}

.lh-16 {
  line-height: 16px;
}

.text-main {
  color: #c0a486;
}

.text-brown {
  color: #874C3F;
}
</style>
<script setup>
</script>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  beforeMount () {
    this.loadSettings()
  },
  computed: {
    bgColor () {
      return this.$route.path === '/login' ? 'rgb(0 0 0 / 84%)' : this.$route.path === '/signup' ? '#fff' : '#eee'
    },
    ...mapState(['alerts'])
  },
  methods: {
    ...mapActions('site', ['loadSettings'])
  },
  unmounted () {
    this.$store.commit('auth/setAuth', {})
    this.$store.commit('auth/setLoggedIn', false)
  }
}
</script>
