<template>
  <div
          class="wrapper position-relative h-100"
  >
    <div class="text-container">
      <h1 :style="{
        backgroundImage: 'url('+ bg + ')'
      }">
        VO.HA.B
      </h1>
    </div>
    <div class="main-panel">
      <login-view/>
    </div>
  </div>
</template>

<script>

import LoginView from '@/views/auth/LoginView.vue'

export default {
  name: 'LoginLayout',
  components: { LoginView },
  data () {
    return {
      bg: require('@/assets/images/background/login_bg.jpg')
    }
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

.text-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
}

.text-container h1 {
  margin: 0;
  color: rgba(225, 225, 225, .01);
  background-repeat: repeat;
  backdrop-filter: blur(10px);
  -webkit-background-clip: text;
  animation: animate 15s ease-in-out infinite;
  text-align: center;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  top: -200px;
}

@keyframes animate {
  0%, 100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}
</style>
