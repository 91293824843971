<template>
  <div class="home h-100 mt-3">
    <v-row class="h-100">
      <v-col v-for="(mainRouter, i) in mainRouters" :key="'main-router-icon-' + i" cols="12"
             :md="auth.usersAuth === 'A' ? '4' : '6'" class="pa-0 h-100">
        <main-router-icon
                :image="mainRouter.image"
                :title="mainRouter.title"
                :link="mainRouter.link"
        />
      </v-col>
      <v-col v-if="auth.usersAuth === 'A'" cols="12" md="4" class="pa-0 h-100">
        <main-router-icon
                :image="require('@/assets/images/background/manage/manageUserImage.jpg')"
                title="Manage"
                link="/manage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import MainRouterIcon from '@/components/home/MainRouterIcon.vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    MainRouterIcon
  },
  computed: {
    ...mapState('auth', ['auth'])
  },
  data () {
    return {
      mainRouters: [
        {
          image: require('@/assets/images/sample/models/hair_model_1.jpg'),
          title: 'Styles',
          link: '/styles'
        },
        {
          image: require('@/assets/images/sample/models/shopping_cart_1.jpg'),
          title: 'Products',
          link: '/products'
        }
      ]
    }
  }
}
</script>
