import StylesView from '@/views/styles/StylesView.vue'
import StylesHome from '@/views/styles/StylesHome.vue'
import ProductsHome from '@/views/products/ProductsHome.vue'
import ProductsView from '@/views/products/ProductsView.vue'
import DefaultLayout from '@/layout/DefaultLayout.vue'
import HomeView from '@/views/HomeView.vue'
import LoginLayout from '@/layout/LoginLayout.vue'
import LoginView from '@/views/auth/LoginView.vue'
import SignUpLayout from '@/layout/SignUpLayout.vue'
import SignUpView from '@/views/auth/SignUpView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import ManageHome from '@/views/manage/ManageHome.vue'
import ManageMembersView from '@/views/manage/members/ManageMembersView.vue'
import ManageStyleView from '@/views/manage/styles/ManageStyleView.vue'
import TermsLayout from '@/layout/TermsLayout.vue'
import TermsView from '@/views/auth/TermsView.vue'
import WithdrawView from '@/views/settings/WithdrawView.vue'
import IntroduceView from '@/views/aria/IntroduceView.vue'
import BoardView from '@/views/board/BoardView.vue'

const routes = [
  {
    path: '/',
    name: 'root',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/company',
        name: 'company',
        component: IntroduceView
      },
      {
        path: '/styles',
        name: 'stylesHome',
        component: StylesHome
      },
      {
        path: '/styles/:index',
        name: 'stylesView',
        component: StylesView
      },
      {
        path: '/products',
        name: 'ProductsHome',
        component: ProductsHome
      },
      {
        path: '/products/:index',
        name: 'ProductsView',
        component: ProductsView
      },
      {
        path: '/community/:pageNum',
        name: 'boardView',
        component: BoardView
      },
      {
        path: '/setting',
        name: 'settingsView',
        component: SettingsView
      },
      {
        path: '/setting/withdraw',
        name: 'withdrawView',
        component: WithdrawView
      },
      {
        path: '/manage',
        name: 'manageHome',
        component: ManageHome
      },
      {
        path: '/manage/members/:pageNum',
        name: 'manageMembersView',
        component: ManageMembersView
      },
      {
        path: '/manage/styles/:pageNum',
        name: 'manageStylesView',
        component: ManageStyleView
      }
    ]
  },
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '/',
        name: 'login',
        component: LoginView
      }
    ]
  },
  {
    path: '/signup',
    component: SignUpLayout,
    children: [
      {
        path: '/',
        name: 'signup',
        component: SignUpView
      }
    ]
  },
  {
    path: '/user-term',
    component: TermsLayout,
    children: [
      {
        path: '/',
        name: 'terms',
        component: TermsView
      }
    ]
  },
  {
    path: '/privacy-term',
    component: TermsLayout,
    children: [
      {
        path: '/',
        name: 'terms',
        component: TermsView
      }
    ]
  }
]

export default routes
