<template>
  <v-row>
    <v-col cols="12">
      <v-sheet class="px-2 px-md-15 py-5">
        <div class="py-3 text-13">
          <div class="px-2 py-1 bg-black d-inline-block" :style="{ border: '1px solid black' }">
            {{ auth.franchise ? auth.franchise.businessName : 'Manage' }}
          </div>
          <div class="px-2 py-1 font-weight-bold d-inline-block" :style="{ border: '1px solid black' }">
            Members
          </div>
          <v-dialog v-model="showDelegatePopup" :fullscreen="$isMobile()" :max-width="$isMobile() ? '100%' : 300">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="px-2 ml-2" size="sm" color="black" rounded="0" flat :style="{ height: '29.5px', border: '2px solid black' }">
                매니저 위임
              </v-btn>
            </template>
            <delegate-manager-popup @close="closeDelegatePopup" />
          </v-dialog>
        </div>
        <v-table class="member-table">
          <thead>
          <tr>
            <th id="user-id" class="2/12 text-center">
              ID
            </th>
            <th id="user-nickname" class="2/12 text-center">
              Nickname
            </th>
            <th id="user-status" class="2/12 text-center">
              Status
            </th>
            <th id="user-phone" class="2/12 text-center">
              Phone
            </th>
            <th id="user-join-date" class="2/12 text-center">
              Join
            </th>
            <th id="user-recent-date" class="2/12 text-center">
              Recent
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in pagination.list" :key="`user-${item.idx}`">
            <tr>
              <td class="text-center">
                {{ item.id }}
              </td>
              <td class="text-center">
                {{ item.username }}
              </td>
              <td class="text-center">
                <v-btn size="sm" rounded="sm" class="mx-1 px-3 py-1"
                       :color="item.status === 'active' ? 'black' : 'warning'" flat @click="updateUserStatus(item)">
                  {{ item.status === 'active' ? '활동' : item.status === 'inactive' ? '대기' : item.status === 'withdraw' ? '탈퇴' : '-' }}
                </v-btn>
                <v-btn size="sm" rounded="sm" class="mx-1 px-3 py-1" color="red" flat @click="deleteUser(item)">
                  중지
                </v-btn>
              </td>
              <td class="text-center">
                {{ item.phone }}
              </td>
              <td class="text-center">
                {{ $parseDate(item.joinDate, 'yyyy.MM.DD') || '-' }}
              </td>
              <td class="text-center">
                {{ $parseDate(item.recentDate, 'YY.MM.DD.HH:mm:ss') || '-' }}
              </td>
            </tr>
          </template>
          </tbody>
        </v-table>
        <v-pagination
                v-model="pageNum"
                color="grey"
                active-color="black"
                :length="pagination.maxPageNum"
                :total-visible="10"
                rounded="xl"
                variant="plain"
                density="compact"
                class="mt-2"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import DelegateManagerPopup from '@/components/manage/DelegateManagerPopup.vue'

export default {
  name: 'ManageMembersView',
  components: { DelegateManagerPopup },
  data () {
    return {
      pagination: {
        list: []
      },
      pageNum: this.$route.params.pageNum ? parseInt(this.$route.params.pageNum) : 1,
      pageDataSize: this.$route.query.pageDataSize || 10,
      keyword: this.$route.query.keyword || null,
      showDetails: false,
      selectedUser: '',
      showDelegatePopup: false
    }
  },
  computed: {
    path () {
      return this.$route.fullPath
    },
    ...mapState('auth', ['auth'])
  },
  watch: {
    selectedUser () {
      this.showDetails = !!this.selectedUser
    },
    pageNum () {
      this.$router.push({
        path: `/members/${this.pageNum}`,
        query: this.$route.query
      })
    },
    path () {
      this.getPagination(this.$route.params.pageNum)
    }
  },
  beforeMount () {
    this.getPagination()
  },
  methods: {
    closeDelegatePopup () {
      this.showDelegatePopup = false
    },
    initQuery () {
      this.keyword = null
      this.status = null
      this.condition = null
      this.$router.push('/members/1')
    },
    getUser (id) {
      this.selectedUser = id
    },
    enterSearch () {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    search () {
      if (this.keyword && !this.condition) {
        this.$alert('조건을 선택해주세요.', 'warning')
        return
      }
      const query = {
        pageDataSize: this.pageDataSize,
        keyword: this.keyword,
        auth: this.auth,
        condition: this.condition,
        status: this.status
      }
      this.$router.push({
        path: '/members/1',
        query: query
      })
      this.getPagination(1)
    },
    async getPagination (pageNum) {
      const url = '/manage/user/list'
      const query = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize,
        keyword: this.keyword,
        condition: this.condition,
        status: this.status
      }
      await this.$axios.get(url, { params: query }).then(res => {
        if (res.data) {
          this.pagination = res.data
        }
      })
    },
    async updateUserStatus (user) {
      let msg = ''
      if (user.status === 'active') {
        msg = `'${user.id}' 사용자를 대기 상태로 변경하시겠습니까?`
      } else {
        msg = `'${user.id}' 사용자를 활동 상태로 변경하시겠습니까?`
      }
      if (confirm(msg)) {
        await this.$axios.post('/manage/user', user).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.$alert('사용자의 상태를 변경하였습니다.', 'success')
            this.getPagination()
          } else {
            this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
          }
        }).catch(() => {
          this.$alert('오류가 발생하였습니다.', 'error')
        })
      }
    },
    async deleteUser (user) {
      const msg = `'${user.id}'사용자를 중지처리 하게되면, 더이상 사용자가 노출되지 않습니다.\n사용자를 중지처리 하시겠습니까?`
      if (confirm(msg)) {
        await this.$axios.delete(`/manage/user?idx=${user.idx}`).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.$alert('사용자의 상태를 변경하였습니다.', 'success')
            this.getPagination()
          } else {
            this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
          }
        }).catch(() => {
          this.$alert('오류가 발생하였습니다.', 'error')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.member-table {
  th {
    background-color: black;
    color: white;
    font-size: 13px;
  }

  td {
    font-size: 12px;
  }
}
</style>
