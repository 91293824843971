<template>
  <div>
    <v-list v-if="pagination && pagination.list.length >0" class="py-0 h-100 bg-grey-lighten-4">
      <template v-for="item in pagination.list" :key="'notice-' + item.idx">
        <v-divider color="grey"/>
        <v-list-item class="pa-0 bg-white" @click="showNoticeDetail(item)">
          <div class="px-3">
            <div class="d-flex align-center py-2">
              <p class="text-13 lh-13 pr-2 w-75 text-truncate">
                {{ item.noticeTitle }}
              </p>
              <v-spacer/>
              <p class="text-13 lh-13 text-grey-darken-2 text-no-wrap">
                관리자
              </p>
            </div>
          </div>
        </v-list-item>
      </template>
      <v-divider color="grey"/>
      <v-dialog v-if="selectedNotice" v-model="showNotice" width="600px" :fullscreen="$isMobile()"
                transition="dialog-bottom-transition">
        <template #default="{ isActive }">
          <default-layer class="card-text-px-0 card-text-py-0">
            <template #title>
              <template v-if="$isMobile()">
                <v-row>
                  <v-col cols="6">
                    <v-btn
                            flat
                            color="transparent"
                            icon
                            size="small"
                            @click="isActive.value = false"
                    >
                      <v-icon>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <span class="text-14">
                            공지사항
                          </span>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="6" class="d-flex align-center justify-start">
                    <h5 class="text-14 font-weight-medium pl-3 lh-14">
                      공지사항
                    </h5>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                            flat
                            size="small"
                            @click="isActive.value = false"
                    >
                      Close
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template #content>
              <v-row>
                <v-col cols="12">
                  <div class="px-5 py-1 text-subtitle-1">
                    {{ selectedNotice.noticeTitle }}
                  </div>
                  <p class="text-14 px-5 pb-2" :class="{ 'text-left': !$isMobile(), 'text-right': $isMobile() }">
                    <span>
                      {{ $parseDate(selectedNotice.registerDate, 'yyyy.MM.DD.') }}
                    </span>
                    <span class="ml-2">
                      관리자
                    </span>
                  </p>
                  <v-divider/>
                  <div
                          class="pa-5"
                          :style="{ fontSize: '16px' }"
                          v-html="selectedNotice.noticeContent"
                  />
                </v-col>
              </v-row>
            </template>
          </default-layer>
        </template>
      </v-dialog>
    </v-list>
    <v-list v-else class="py-0 h-100 bg-grey-lighten-4">
      <v-divider color="grey"/>
      <v-list-item class="pa-0 bg-white">
        <div class="px-3 py-15 text-center text-body-2">
          공지사항이 등록되지 않았습니다.
        </div>
      </v-list-item>
    </v-list>
    <div v-if="showPrev || showNext" class="d-flex mt-3">
      <v-btn v-if="showPrev" flat rounded="0" @click="prevPage">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        이전
      </v-btn>
      <v-spacer/>
      <v-btn v-if="showNext" flat rounded="0" @click="nextPage">
        다음
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'

export default {
  name: 'NoticePopup',
  components: { DefaultLayer },
  data () {
    return {
      pagination: {
        list: []
      },
      pageNum: 1,
      pageDataSize: 10,
      showNotice: false,
      selectedNotice: {}
    }
  },
  computed: {
    showNext () {
      return this.pagination && this.pageNum < this.pagination.maxPageNum
    },
    showPrev () {
      return this.pagination && this.pageNum > 1
    }
  },
  watch: {
    pageNum (val) {
      this.getPagination(val)
    }
  },
  beforeMount () {
    this.getPagination()
  },
  methods: {
    async getPagination (pageNum) {
      const params = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize
      }
      await this.$axios.get('/notice/list', { params: params }).then(res => {
        this.pagination = res.data
      })
    },
    showNoticeDetail (notice) {
      this.selectedNotice = notice
      if (this.selectedNotice) {
        this.showNotice = true
      }
    },
    nextPage () {
      if (this.pagination && this.pageNum < this.pagination.maxPageNum) {
        this.pageNum++
      }
    },
    prevPage () {
      if (this.pagination && this.pageNum > 1) {
        this.pageNum--
      }
    },
    close () {
      this.showNotice = false
    }
  }
}
</script>

<style scoped>

</style>
