export default {
  namespaced: true,
  state: {
    show: false,
    text: '',
    type: ''
  },
  mutations: {
    setContent: (state, payload) => {
      state.text = payload.text
      state.type = payload.type || 'success'
    },
    setShow: (state, payload) => {
      state.show = payload
    }
  },
  actions: {
    alert: ({ commit }, payload) => {
      commit('setContent', payload)
      commit('setShow', true)
      setTimeout(() => {
        commit('setShow', false)
      }, 5000)
    }
  }
}
