<template>
  <v-row>
    <v-col cols="12">
      <v-sheet class="px-2 px-md-15 py-5">
        <div class="py-3 text-13 d-flex align-center flex-wrap">
          <div class="px-2 py-1 bg-black d-inline-block" :style="{ border: '1px solid black' }">
            {{ auth.franchise ? auth.franchise.businessName : 'Manage' }}
          </div>
          <div class="px-2 py-1 font-weight-bold d-inline-block" :style="{ border: '1px solid black' }">
            Styles
          </div>
        </div>
        <v-tabs v-model="album">
          <template v-for="item in albums" :key="`album-tab-${item.idx}`">
            <v-tab :value="item.idx">
              {{ item.albumName }}
            </v-tab>
          </template>
        </v-tabs>
        <div class="style-table d-flex">
          <v-table :style="{ minWidth: '130px' }">
            <thead>
            <tr>
              <th id="style-image" class="w-25 text-center">
                Image
              </th>
            </tr>
            </thead>
            <draggable
                    v-if="pagination && pagination.list.length > 0"
                    v-model="pagination.list"
                    tag="tbody"
                    item-key="idx"
                    @change="checkMove"
            >
              <template #item="{element}">
                <tr>
                  <td class="text-center" :style="{ maxHeight: '100px !important' }">
                    <v-img :src="element.imageSrc" :lazy-src="element.imageSrc" :style="{ height: '99px' }"/>
                  </td>
                </tr>
              </template>
            </draggable>
            <tbody v-else>
            <tr>
              <td colspan="1" class="text-center py-15">
              </td>
            </tr>
            </tbody>
          </v-table>
          <v-table class="w-100">
            <thead>
            <tr>
              <th id="style-image" class="w-25 text-center">
                Album
              </th>
              <th id="style-image" class="w-50 text-center">
                Name
              </th>
            </tr>
            </thead>
            <tbody v-if="pagination && pagination.list.length > 0">
              <tr v-for="element in pagination.list" :key="`style-${element.idx}`" @click="getStyle(element.idx)" >
                <td class="text-center" :style="{ height: '100px !important' }">
                  {{ element.album.albumName }}
                </td>
                <td class="text-center" :style="{ height: '100px !important' }">
                  {{ element.imageName }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="2" class="text-center py-15">
                검색된 스타일이 존재하지 않습니다.
              </td>
            </tr>
            </tbody>
          </v-table>
          <v-table>
            <thead>
            <tr>
              <th class="text-center py-1">
                Sort
              </th>
            </tr>
            </thead>
            <tbody
                    v-if="pagination.list.length > 0"
            >
              <tr
                      :id="`style-${element.idx}`"
                      v-for="element in pagination.list"
                      :key="`style-${element.idx}`"
                      class="cursor-pointer hover:bg-neutral-700 transition duration-300"
              >
                <td :style="{ height: '100px' }">
                  <div class="d-flex justify-center align-center">
                    <v-btn v-if="element.sortOrder < pagination.maxSortOrder" icon="mdi-chevron-down" class="border" rounded="0" elevation="0" size="x-small" @click="changeSort(element, 1)" />
                    <v-sheet :style="{ backgroundColor: 'transparent' }" class="mx-3">
                      {{ element.sortOrder }}
                    </v-sheet>
                    <v-btn v-if="element.sortOrder > 1" icon="mdi-chevron-up" rounded="0" class="border" size="x-small" elevation="0" @click="changeSort(element, -1)"/>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="1">
              </td>
            </tr>
            </tbody>
          </v-table>
        </div>
        <v-pagination
                v-model="pageNum"
                color="grey"
                active-color="black"
                :length="pagination.maxPageNum"
                :total-visible="10"
                rounded="xl"
                variant="plain"
                density="compact"
                class="mt-2"
        />
        <div class="d-flex">
          <v-dialog v-model="showManageAlbums" :max-width="$isMobile() ? '100%' : 800" persistent
                    :fullscreen="$isMobile()">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="text-13 py-1 px-2" size="sm" rounded="0" color="black" flat>
                앨범관리
              </v-btn>
            </template>
            <manage-album-popup :albums="albums" @update-albums="getAlbums" @close-album="closeAlbum"/>
          </v-dialog>
          <v-spacer/>
          <v-dialog v-model="showRegister" :max-width="$isMobile() ? '100%' : 800" persistent :fullscreen="$isMobile()">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="text-13 py-1 px-2" size="sm" rounded="0" color="black" flat>
                스타일 등록
              </v-btn>
            </template>
            <manage-style-register :albums="albums" @update-style="getPagination(1)" @close-register="closeRegister"/>
          </v-dialog>
        </div>
      </v-sheet>
      <v-dialog v-model="showEdit" :max-width="$isMobile() ? '100%' : 800" persistent :fullscreen="$isMobile()">
        <manage-style-edit :albums="albums" :style-idx="selectedStyle" @update-style="getPagination(1)"
                           @close-edit="closeEdit"/>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import ManageAlbumPopup from '@/components/manage/ManageAlbumPopup.vue'
import ManageStyleRegister from '@/components/manage/ManageStyleRegister.vue'
import ManageStyleEdit from '@/components/manage/ManageStyleEdit.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ManageStyleView',
  components: {
    ManageStyleEdit,
    ManageStyleRegister,
    ManageAlbumPopup,
    draggable
  },
  data () {
    return {
      pagination: {
        list: []
      },
      pageNum: this.$route.params.pageNum ? parseInt(this.$route.params.pageNum) : 1,
      pageDataSize: this.$route.query.pageDataSize || 20,
      keyword: this.$route.query.keyword || null,
      album: null,
      condition: this.$route.query.condition || '',
      showEdit: false,
      selectedStyle: null,
      showRegister: false,
      showManageAlbums: false,
      albums: []
    }
  },
  watch: {
    selectedStyle (val) {
      if (val) {
        this.showEdit = !!this.selectedStyle
      }
    },
    pageNum () {
      this.$router.push({
        path: `/manage/styles/${this.pageNum}`,
        query: this.$route.query
      })
    },
    path () {
      this.getPagination(this.$route.params.pageNum)
    },
    album (val) {
      this.$router.push({
        path: '/manage/styles/1',
        query: { albumIdx: val }
      })
      this.pageNum = 1
      this.getPagination(1)
    }
  },
  computed: {
    ...mapState('auth', ['auth'])
  },
  beforeMount () {
    this.getAlbums()
  },
  mounted () {
  },
  methods: {
    closeAlbum () {
      this.showManageAlbums = false
    },
    closeRegister () {
      this.showRegister = false
    },
    closeEdit () {
      this.selectedStyle = null
      this.showEdit = false
      this.getPagination()
    },
    getStyle (idx) {
      this.selectedStyle = idx
    },
    enterSearch (event) {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    search () {
      const query = {
        pageDataSize: this.pageDataSize,
        keyword: this.keyword,
        condition: this.condition
      }
      if (this.album) {
        query.albumIdx = this.album
      }
      this.$route.push({
        path: '/manage/styles/1',
        query: query
      })
      this.getPagination(1)
    },
    async getPagination (pageNum) {
      const query = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize,
        albumIdx: this.album
      }
      await this.$axios.get('/manage/style/list', { params: query }).then(res => {
        if (res.data) {
          this.pagination = res.data
        }
      })
    },
    async getAlbums () {
      await this.$axios.get('/manage/style/album/list').then(res => {
        this.albums = res.data
        for (const a of this.albums) {
          if (!this.album) {
            this.$router.push(`/manage/styles/1?albumIdx=${a.idx}`)
            this.album = a.idx
            return
          }
        }
        this.getPagination()
      })
    },
    changeSort (style, amount) {
      const params = Object.assign({}, style)
      params.sortOrder = params.sortOrder + amount
      this.$axios.post('/manage/style/sort/change', params).finally(() => {
        this.getPagination()
      })
    },
    checkMove (event) {
      const moved = event.moved
      if (moved) {
        const element = moved.element
        if (element) {
          const movedIdx = element.idx
          let listIndex
          if (moved.oldIndex > moved.newIndex) {
            listIndex = moved.newIndex + 1
          } else {
            listIndex = moved.newIndex - 1
          }
          const targetElement = this.pagination.list[listIndex]
          const targetIdx = targetElement.idx
          const params = {
            movedIdx,
            targetIdx
          }
          this.$axios.post('/manage/style/sort', params).finally(() => {
            this.getPagination()
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.style-table {
  th {
    background-color: black;
    color: white;
    font-size: 13px;
  }

  td {
    font-size: 12px;
  }
}

.album-select {
  border: 1px solid black;

  &:focus {
    outline: none;
  }
}
</style>
