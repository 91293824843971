<template>
  <v-card class="px-0 no-scroll">
    <v-card-title class="d-flex position-fixed w-100 bg-white" :style="{ left: 0, top: 0, zIndex: 1, height: '50px' }">
      <div class="w-75 text-14">
        {{ selectedProduct.productName }}
      </div>
      <div class="w-25 text-right">
        <v-btn size="xs" flat @click="close">
              <span class="text-12">
                Close
              </span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="pa-0" :style="{ marginTop: '50px' }">
      <v-img :src="selectedProduct.imageSrc" :lazy-src="selectedProduct.imageSrc" :style="{ maxHeight: '700px' }"
             width="100%" cover/>
    </v-card-text>
    <v-card-text class="px-0 d-flex flex-wrap">
      <div class="d-flex w-100">
        <div class="d-flex justify-start align-center w-100 ps-5 text-14 font-weight-bold">
          {{ selectedProduct.productName }}
        </div>
        <div class="d-flex justify-end w-aut text-no-wrap pr-3 text-14 font-weight-bold">
          {{ selectedProduct.productPrice ? parseInt(selectedProduct.productPrice).toLocaleString() + '원' : '-' }}
        </div>
      </div>
      <v-divider class="mt-3"/>
    </v-card-text>
    <v-card-text class="pa-0">
      <div v-if="selectedProduct.instagramLink" class="text-right px-5 cursor-pointer" :style="{ width: 'fit-content', float: 'right' }" @click="openInstagramLink">
        <div class="d-inline-block">
          <v-img :src="getSnsIconStyle" :lazy-src="getSnsIconStyle" class="mr-2" width="20px"
                 :style="{ position: 'relative', top: '5px' }"/>
        </div>
        <span>
          {{ getSnsName }}
        </span>
      </div>
    </v-card-text>
    <v-card-text class="pa-0">
      <div class="px-5 py-3 product-content" v-html="selectedProduct.productContent"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProductsDetailCard',
  props: ['selectedProduct'],
  computed: {
    getSnsIconStyle () {
      if (this.selectedProduct) {
        const link = this.selectedProduct.instagramLink
        if (link.indexOf('facebook') > -1) {
          return require('@/assets/images/icon/facebook_icon.png')
        } else if (link.indexOf('youtu.be') > -1 || link.indexOf('youtube') > -1) {
          return require('@/assets/images/icon/youtube_icon.png')
        } else if (link.indexOf('instagram') > -1) {
          return require('@/assets/images/icon/instagram_icon.png')
        } else {
          return require('@/assets/images/icon/url_icon.png')
        }
      } else {
        return null
      }
    },
    getSnsName () {
      if (this.selectedProduct) {
        const link = this.selectedProduct.instagramLink
        if (link.indexOf('facebook') > -1) {
          return 'Facebook'
        } else if (link.indexOf('youtu.be') > -1 || link.indexOf('youtube') > -1) {
          return 'Youtube'
        } else if (link.indexOf('instagram') > -1) {
          return 'Instagram'
        } else {
          return '외부링크 바로가기'
        }
      } else {
        return null
      }
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    openInstagramLink () {
      window.open(this.selectedProduct.instagramLink, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.v-card-text:deep(.product-content) {
  font-size: 14px;

  img {
    max-width: 100%;
    border-radius: 5px;
    margin: 5px 0;
  }
}
</style>
