import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import utils from './plugins/utils'
import date from './plugins/date'
import moment from 'moment/moment'
import VueCookies from 'vue-cookies'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import VueMobileDetection from 'vue3-mobile-detection'
import VueDisableAutocomplete from 'vue-disable-autocomplete'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

moment.locale('ko')
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi'
  }
})

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(VueDisableAutocomplete)
app.use(VueMobileDetection)
app.use(vuetify)
app.use(store)
app.use(router)
app.use(VueCookies, {
  expires: '7d'
})
app.use(utils)
app.use(date)
app.use(VueVideoPlayer)
app.mount('#app')
