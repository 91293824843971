<template>
  <v-card>
    <v-card-title class="pb-0 d-flex">
      <h3 class="text-15">
        매니저 위임
      </h3>
      <v-spacer />
      <v-btn size="small" rounded="0" class="px-2 py-1" flat @click="close">
        Close
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-list class="pa-0 no-scroll" max-height="300px" :style="{ overflowY: 'auto' }">
        <template v-for="(item, i) in members" :key="`delegate-user-${item.idx}`">
          <v-divider v-if="i !== 0" />
          <v-list-item class="text-13 text-center" height="30px" min-height="20px" @click="delegateUser(item)">
            <span class="mr-2">
              {{ item.username }}
            </span>
            <span class="mr-2">
              {{ item.id }}
            </span>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DelegateManagerPopup',
  data () {
    return {
      members: []
    }
  },
  beforeMount () {
    this.getMembers()
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async getMembers () {
      await this.$axios.get('/manage/user/list/all').then(res => {
        if (res.data) {
          this.members = res.data
        }
      })
    },
    async delegateUser (user) {
      if (confirm(`매니저 위임 시 재 로그인이 필요합니다. 매니저를 ${user.username}에게 위임하시겠습니까?`)) {
        await this.$axios.post('/manage/user/delegate', user).then(res => {
          if (res.data.status === 'SUCCESS') {
            alert('매니저를 위임했습니다. 로그인페이지로 돌아갑니다.')
            this.logout()
          } else {
            this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
          }
        }).catch(() => {
          this.$alert('오류가 발생하였습니다.', 'error')
        })
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
