<template>
  <default-layer class="card-text-px-0 card-text-py-0">
    <template #title>
      <template v-if="$isMobile()">
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <v-btn
              flat
              color="transparent"
              icon="mdi-chevron-left"
              size="small"
              @click="close"
            />
            <div class="text-14">
              Community
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-start">
            <h5 class="text-14 font-weight-medium pl-3 lh-14">
              글 작성
            </h5>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              flat
              size="small"
              @click="close"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
    <template #content>
      <v-row>
        <v-col cols="12">
          <div class="px-5 d-flex align-end">
            <v-btn-toggle v-model="options" :style="{ height: '30px' }" selected-class="bg-white" multiple>
              <v-btn v-if="auth && auth.master" value="pinned" flat size="x-small" rounded="0" :style="{ minHeight: '30px', minWidth: '30px', width: '20px', height: '20px' }">
                <v-icon :color="pinnedColor" size="x-large">
                  {{ pinnedIcon }}
                </v-icon>
              </v-btn>
              <v-btn value="secret" flat size="x-small" rounded="0" class="mr-2" :style="{ minHeight: '30px', minWidth: '30px', width: '20px', height: '20px' }">
                <v-icon :color="secretColor" size="x-large">
                  {{ secretIcon }}
                </v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-text-field v-model="board.articleTitle" hide-details variant="underlined" placeholder="제목을 입력해주세요." label="Title" />
          </div>
        </v-col>
        <v-col cols="12" class="pb-5 pb-md=0">
          <div class="px-5">
            <custom-editor ref="boardEditor" height="400px" image :content="board.articleContent" />
          </div>
        </v-col>
      </v-row>
      <div class="px-5 pb-5">
        <v-btn color="black" rounded="0" flat class="w-100" size="large" @click="registerBoard">
          작성하기
        </v-btn>
      </div>
    </template>
  </default-layer>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'
import CustomEditor from '@/components/editor/Editor.vue'
import { mapState } from 'vuex'

export default {
  name: 'BoardRegister',
  components: { CustomEditor, DefaultLayer },
  data () {
    return {
      board: {
        articleTitle: '',
        articleContent: '',
        secret: false,
        pinned: false
      },
      options: []
    }
  },
  computed: {
    ...mapState('auth', ['auth']),
    secretIcon () {
      return this.options.includes('secret') ? 'mdi-lock' : 'mdi-lock-open-outline'
    },
    secretColor () {
      return this.options.includes('secret') ? 'black' : 'grey'
    },
    pinnedIcon () {
      return this.options.includes('pinned') ? 'mdi-pin' : 'mdi-pin-off-outline'
    },
    pinnedColor () {
      return this.options.includes('pinned') ? 'black' : 'grey'
    }
  },
  watch: {
    options () {
      this.board.secret = this.options.includes('secret')
      this.board.pinned = this.options.includes('pinned')
    }
  },
  methods: {
    async registerBoard () {
      if (!this.board.articleTitle) {
        alert('글 제목을 입력해주세요.')
        return
      }
      const content = this.$refs.boardEditor.getContent()
      if (!content) {
        alert('내용을 입력해주세요.')
        return
      }
      this.board.articleContent = content
      if (confirm('글을 작성하시겠습니까?')) {
        await this.$axios.put('/board', this.board).then(() => {
          this.refreshPagination()
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      }
    },
    refreshPagination () {
      this.$emit('refresh-pagination')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
