<template>
  <v-card rounded="0">
    <v-card-title class="pb-3 d-flex">
      <v-btn size="small" rounded="0" class="px-2 py-1" flat color="red" @click="deleteStyle">
        Delete
      </v-btn>
      <v-spacer/>
      <v-btn size="small" rounded="0" class="px-2 py-1" flat @click="close">
        Close
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0">
      <div v-if="style.imageSrc" class="mb-2">
        <v-img :src="style.imageSrc" :lazy-src="style.imageSrc" width="100%" max-height="200px"/>
      </div>
      <div class="d-flex mb-3">
        <v-text-field
                v-model="style.imageName"
                placeholder="스타일명"
                density="compact"
                hide-details
                variant="underlined"
        />
        <v-btn size="small" rounded="0" class="px-2 py-1 ml-2" flat color="black" @click="triggerFileInput">
          <v-icon class="mr-1">
            mdi-image
          </v-icon>
          사진등록
        </v-btn>
        <input
                ref="fileInput"
                type="file"
                accept="image/*"
                class="d-none"
                @change="handleFileChange"
        />
      </div>
      <div class="mb-3">
        <v-text-field
                v-model="style.instagramLink"
                placeholder="SNS 링크 (Instagram, Facebook, Youtube)"
                density="compact"
                hide-details
                variant="underlined"
        />
      </div>
      <div class="mb-3">
        <v-autocomplete
                v-model="style.usedProducts"
                multiple
                chips
                item-title="productName"
                label="사용기기"
                item-value="idx"
                variant="underlined"
                density="compact"
                hide-details
                :items="products"
                class="w-100 mb-5 mt-5"
                no-data-text="검색 된 제품이 없습니다."
        />
        <v-autocomplete
                ref="franchise"
                v-model="style.album.idx"
                label="앨범"
                density="compact"
                item-title="albumName"
                item-value="idx"
                hide-details
                variant="underlined"
                no-data-text="검색된 앨범이 없습니다."
                :items="albums"
                class="w-100 mb-3"
        />
      </div>
      <div>
        <custom-editor ref="styleEditor" height="300px" image :content="style.imageContent"/>
      </div>
    </v-card-text>
    <div>
      <v-btn class="w-100" rounded="0" size="large" flat color="black" @click="modifyStyle">
        스타일 수정
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import CustomEditor from '@/components/editor/Editor'

export default {
  name: 'ManageStyleEdit',
  components: { CustomEditor },
  props: ['albums', 'styleIdx'],
  data () {
    return {
      style: {
        imageName: '',
        imageSrc: '',
        videoSrc: '',
        usedProducts: null,
        imageContent: '',
        album: {
          idx: null
        },
        instagramLink: ''
      },
      products: []
    }
  },
  beforeMount () {
    this.getStyle()
    this.getProducts()
  },
  methods: {
    async getStyle () {
      await this.$axios.get(`/style/detail/${this.styleIdx}`).then(res => {
        this.style = res.data
        if (this.style.usedProducts) {
          this.style.usedProducts = this.parseUsedProductToArray(this.style.usedProducts)
          if (!this.style.franchiseIdx) {
            this.style.franchiseIdx = 0
          }
        }
        if (!this.style.album || !this.style.album.idx) {
          this.style.album.idx = 0
        }
        if (this.style.imageContent) {
          this.$refs.styleEditor.setContent(this.style.imageContent)
        }
      })
    },
    close () {
      this.$emit('close-edit')
    },
    triggerFileInput () {
      this.$refs.fileInput.click()
    },
    async handleFileChange (event) {
      const formData = new FormData()
      const files = event.target.files
      const image = files[0]
      const maxImageSize = 5 * 1024 * 1024
      if (image.size > maxImageSize) {
        this.$alert('이미지의 크기는 최대 5MB를 초과 할 수 없습니다.', 'warning')
        return
      }
      formData.append('image', image)
      await this.$axios.post('/attachment/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data) {
          this.style.imageSrc = `/api/attachment/image/${res.data.url}`
        }
      }).catch(err => {
        console.log(err)
      })
    },
    parseUsedProductToArray (usedProducts) {
      const usedArray = usedProducts.split(',')
      usedProducts = []
      for (const p of usedArray) {
        usedProducts.push(parseInt(p))
      }
      return usedProducts
    },
    async modifyStyle () {
      const content = this.$refs.styleEditor.getContent()
      if (!this.style) {
        this.$alert('오류가 발생하였습니다. 새로고침 후 이용해주세요.', 'error')
        return
      }
      if (!this.style.imageName) {
        this.$alert('스타일명을 입력해주세요.', 'warning')
        return
      }
      if (!this.style.imageSrc) {
        this.$alert('스타일 대표사진을 업로드해주세요..', 'warning')
        return
      }
      if (!content) {
        this.$alert('스타일에 대한 내용을 입력해주세요.', 'warning')
        return
      }
      this.style.imageContent = content
      if (!confirm('스타일을 수정하시겠습니까?')) {
        return
      }
      if (this.style.usedProducts) {
        this.style.usedProducts = this.style.usedProducts.toString()
      }
      await this.$axios.post('/manage/style', this.style).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert('스타일 수정이 완료되었습니다.', 'success')
          this.updateStyle()
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
          this.parseUsedProductToArray(this.style.usedProducts)
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
        this.parseUsedProductToArray(this.style.usedProducts)
      })
    },
    async deleteStyle () {
      if (!this.styleIdx) {
        this.$alert('스타일 삭제 중 오류가 발생하였습니다. 새로고침 후 다시 이용해주세요.', 'error')
        return
      }
      if (confirm('삭제한 스타일은 복구가 불가능합니다.\n스타일을 삭제하시겠습니까?')) {
        await this.$axios.delete('/manage/style', { params: { idx: this.styleIdx } }).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.$alert('스타일이 삭제되었습니다.', 'success')
            this.close()
          }
        })
      }
    },
    async getProducts () {
      await this.$axios.get('/product/list/all').then(res => {
        this.products = res.data
      })
    },
    updateStyle () {
      this.$emit('update-style')
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
