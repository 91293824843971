<template>
  <v-bottom-sheet v-model="showSearch">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-magnify" variant="text"/>
    </template>
    <v-card rounded="lg" class="bg-white">
      <v-card-title>
        <div class="mb-3 px-5">
          <v-text-field
                  v-model="keyword"
                  variant="underlined"
                  append-icon="mdi-magnify"
                  placeholder="Keyword"
                  hide-details
                  @keyup="enterSearch"
                  @click:append="search"
          />
        </div>
        <div class="px-5">
          <p class="text-14 text-grey-darken-1 font-weight-bold">
            추천키워드
          </p>
          <v-chip-group v-if="settings">
            <template v-for="(item, i) in keywords" :key="`recommend-keyword-${i}`">
              <v-chip v-if="item" :text="item" size="small" @click="keywordSearch(item)"/>
            </template>
          </v-chip-group>
        </div>
        <div class="px-5">
          <v-divider color="grey" class="mt-4"/>
          <div v-if="!searchResult || searchResult.length === 0" class="py-10">
            <p class="text-13 text-grey text-center">
              검색결과가 없습니다.
            </p>
          </div>
          <div v-else class="py-3" :style="{ maxHeight: '600px', overflowY: 'auto' }">
            <div v-for="(item, i) in searchResult" :key="`search-result-${i}`" class="text-subtitle-2 py-1 d-flex align-center"
               @click="movePage(item.link)">
              <div>
                <v-img v-if="item.image" :src="item.image" width="40px" height="40px" cover rounded="lg" class="mr-3" />
              </div>
              <template v-for="(t, j) in parseTitle(item.title)" :key="`search-result-${i}-title-${j}`">
                <template v-if="t">
                  <span :style="{}" class="font-weight-light text-12">
                    {{ t }}
                  </span>
                  <span v-if="(j + 1) !== parseTitle(item.title).length" class="mx-1">
                    <v-icon class="font-weight-light text-grey-darken-2">
                      mdi-circle-small
                    </v-icon>
                  </span>
                </template>
              </template>
            </div>
          </div>
          <v-divider color="grey" class="mt-2"/>
        </div>
      </v-card-title>
    </v-card>
  </v-bottom-sheet>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'SearchLayer',
  data () {
    return {
      drawerStyle: {
        background: 'rgba(45, 27, 7, 0.9)',
        backdropFilter: 'blur(6px)',
        color: 'white'
      },
      keyword: '',
      searchResult: [],
      showSearch: false
    }
  },
  computed: {
    ...mapState('site', ['settings']),
    keywords () {
      let array = []
      if (this.settings.recommendKeywords) {
        array = this.settings.recommendKeywords.split(',')
      }
      return array
    }
  },
  methods: {
    enterSearch (event) {
      if (event.key === 'Enter') {
        this.search()
      }
    },
    async search () {
      await this.$axios.get(`/site/settings/search?keyword=${this.keyword}`).then(res => {
        this.searchResult = res.data
      })
    },
    keywordSearch (keyword) {
      this.keyword = keyword
      this.search()
    },
    parseTitle (title) {
      return title.split(',')
    },
    movePage (link) {
      this.keyword = ''
      this.searchResult = []
      this.showSearch = false
      this.$router.push(link)
    }
  }
}
</script>

<style scoped>

</style>
