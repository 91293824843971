import axi from 'axios'
import router from '@/router'
import store from '@/store'
const axios = axi.create({
  baseURL: '/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=utf-8'
  },
  withCredentials: 'include'
})
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        store.commit('auth/setAuth', {})
        store.commit('auth/setLoggedIn', false)
        router.push('/auth/login')
        return new Promise(() => {})
      } else {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  }
)

export default axios
