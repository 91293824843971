import store from '../store'
import router from '../router'
export default {
  install (app) {
    app.config.globalProperties.scrollTop = () => {
      window.scrollTo(0, 0)
    }
    app.config.globalProperties.isEmpty = obj => {
      return Object.keys(obj).length === 0
    }
    app.config.globalProperties.getStatus = val => {
      const result = {}
      if (val === 'confirm') {
        result.text = '승인'
        result.color = 'success'
      } else if (val === 'reject') {
        result.text = '반려'
        result.color = 'error'
      } else if (val === 'wait') {
        result.text = '대기'
        result.color = 'warning'
      }
      return result
    }
    app.config.globalProperties.$alert = (text, type, url) => {
      store.dispatch('alerts/alert', { text, type })
      if (url) {
        router.push(url)
      }
    }
    app.config.globalProperties.getReservationStatus = val => {
      let text = ''
      if (val === 'confirm') {
        text = '이용완료'
      } else if (val === 'reject') {
        text = '예약취소'
      } else if (val === 'wait') {
        text = '예약확정'
      }
      return text
    }
    app.config.globalProperties.$parsePhone = phoneNumber => {
      if (phoneNumber) {
        phoneNumber.replace(/^01([0|1|6|7|8|9]?)-?(\d{4})-?(\d{4})$|^(\d{2,3})-?(\d{3,4})-?(\d{4})$/, (match, p1, p2, p3, p4, p5, p6) => {
          if (p1) { // 010-XXXX-XXXX 형식인 경우
            phoneNumber = `010-${p2}-${p3}`
          } else { // 지역번호-XXX-XXXX 또는 지역번호-XXXX-XXXX 형식인 경우
            phoneNumber = `${p4}-${p5}-${p6}`
          }
        })
      }
      return phoneNumber
    }
  }
}
