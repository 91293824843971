import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'

// Modules
import auth from '@/store/modules/auth'
import site from '@/store/modules/site'
import alerts from '@/store/modules/alerts'
export default createStore({
  modules: {
    auth,
    alerts,
    site
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'site']
    })
  ]
})
