<template>
  <div class="h-100 px-5">
    <v-row class="h-100">
      <v-col cols="12" class="py-0 pt-md-10">
        <v-row v-if="albums && albums.length > 0">
          <template v-for="item in albums" :key="`album-${item.idx}`">
            <v-col lg="4" md="4" cols="12" class="position-relative cursor-pointer pa-1"
                   @click="$router.push(item.link)">
              <div class="position-relative album-card">
                <div :style="getCardStyle(item)"/>
                <div class="album-card-overlay d-flex justify-center align-center">
                  <v-icon v-if="!item.firstStyle" class="empty-icon" :style="{ fontSize: '100px', color: 'white' }">
                    mdi-tray-remove
                  </v-icon>
                </div>
                <div class="w-100 text-white zIndex-1 justify-center d-flex align-center" :style="{ height: '180px' }">
                  <h5 class="ghanachoco" :style="{ fontSize: '24px', fontWeight: 600 }">
                    {{ item.albumName }}
                  </h5>
                </div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'StylesHome',
  data () {
    return {
      albums: [],
      styleUrl: '/styles/1?albumIdx='
    }
  },
  beforeMount () {
    this.getAlbums()
  },
  methods: {
    async getAlbums () {
      await this.$axios.get('/style/album/list').then(res => {
        if (res.data) {
          this.albums = res.data
          for (const a of this.albums) {
            a.link = this.styleUrl + a.idx
          }
        }
      })
    },
    getCardStyle (album) {
      const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0'
      }
      if (album && album.firstStyle) {
        style.backgroundImage = `url('${album.firstStyle.imageSrc}')`
        style.backgroundSize = 'cover'
        style.backgroundPosition = 'center'
      } else {
        style.backgroundColor = 'white'
      }
      return style
    }
  }
}
</script>

<style scoped lang="scss">
.album-card {
  border-radius: 5px;
  overflow: hidden;

  .album-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.5;

    .empty-icon {
      opacity: 0.1;
    }
  }
}

</style>
