<template>
  <v-card rounded="0" class="px-0">
    <v-card-title class="text-right pb-0">
      <v-btn size="small" rounded="0" class="px-2 py-1" flat @click="close">
        Close
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0 px-0">
      <v-table>
        <thead>
        <tr>
          <th id="album-no" class="text-center">
            번호
          </th>
          <th id="album-name" class="text-center text-no-wrap">
            앨범명
          </th>
          <th id="album-sort-order" class="text-center">
            정렬순서
          </th>
          <th id="album-manage" class="text-center">
            관리
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-center">
            New
          </td>
          <td class="text-center">
            <v-text-field
                    v-model="newAlbum.albumName"
                    density="compact"
                    hide-details
                    variant="underlined"
                    placeholder="앨범명"
            />
          </td>
          <td class="text-center">
            <v-text-field
                    v-model="newAlbum.sortOrder"
                    type="number"
                    density="compact"
                    hide-details
                    variant="underlined"
                    hide-spin-buttons
            />
          </td>
          <td class="text-center py-1 py-md-0">
            <v-btn size="small" rounded="0" class="px-2 py-1" flat color="black" @click="registerAlbum">
              등록
            </v-btn>
            <v-btn size="small" rounded="0" class="px-2 py-1" flat color="red" @click="initNewAlbum">
              리셋
            </v-btn>
          </td>
        </tr>
        </tbody>
        <tbody>
        <template v-for="(item, i) in albums" :key="`album-${item.idx}`">
          <tr>
            <td class="text-center">
              {{ i + 1 }}
            </td>
            <td class="text-center">
              <v-text-field
                      v-model="item.albumName"
                      density="compact"
                      hide-details
                      variant="underlined"
              />
            </td>
            <td class="text-center">
              <v-text-field
                      v-model="item.sortOrder"
                      density="compact"
                      hide-details
                      variant="underlined"
                      type="number"
                      hide-spin-buttons
              />
            </td>
            <td class="text-center">
              <v-btn size="small" rounded="0" class="px-2 py-1" flat color="black" @click="updateAlbum(item)">
                수정
              </v-btn>
              <v-btn size="small" rounded="0" class="px-2 py-1" flat color="red" @click="deleteAlbum(item.idx)">
                삭제
              </v-btn>
            </td>
          </tr>
        </template>
        </tbody>
      </v-table>
    </v-card-text>
    <div>
      <v-btn class="w-100" rounded="0" size="large" flat color="black" @click="updateAlbums">
        일괄수정
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ManageAlbumPopup',
  props: ['albums'],
  data () {
    return {
      newAlbum: {
        albumName: '',
        sortOrder: 0
      }
    }
  },
  methods: {
    initNewAlbum () {
      this.newAlbum = {
        albumName: '',
        sortOrder: 0
      }
    },
    close () {
      this.$emit('close-album')
    },
    async deleteAlbum (albumIdx) {
      if (!albumIdx) {
        this.$alert('앨범 삭제 중 오류가 발생하였습니다. 새로고침 후 재시도 바랍니다.', 'error')
        return
      }
      const params = {
        albumIdx: albumIdx
      }
      if (!confirm('앨범을 삭제하시겠습니까?\n삭제하게 되면 하위 제품들이 기본 분류로 모두 이동되게 됩니다.')) {
        return
      }
      await this.$axios.delete('/manage/style/album', { params: params }).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert(res.data.message, 'success')
          this.getAlbums()
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
      })
    },
    async registerAlbum () {
      const url = '/manage/style/album'
      if (this.newAlbum === null) {
        this.$alert('앨범 수정 중 오류가 발생하였습니다. 새로고침 후 재시도 바랍니다.', 'error')
        return
      }
      if (!this.newAlbum.albumName) {
        this.$alert('앨범명은 비어있을 수 없습니다.', 'error')
        return
      }
      if (this.newAlbum.sortOrder === null) {
        this.$alert('정렬 순서는 비어있을 수 없습니다.', 'error')
        return
      }
      if (!confirm('앨범을 등록하시겠습니까?')) {
        return
      }
      await this.$axios.put(url, this.newAlbum).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert('제품 분류를 등록하였습니다..', 'success')
          this.getAlbums()
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
      }).finally(() => {
        this.initNewAlbum()
      })
    },
    async updateAlbum (album) {
      const url = '/manage/style/album'
      if (album === null) {
        this.$alert('제품 분류 수정 중 오류가 발생하였습니다. 새로고침 후 재시도 바랍니다.', 'error')
        return
      }
      if (!album.albumName) {
        this.$alert('제품 분류명은 비어있을 수 없습니다.', 'error')
        return
      }
      if (album.sortOrder === null) {
        this.$alert('정렬 순서는 비어있을 수 없습니다.', 'error')
        return
      }
      if (!confirm('정보를 수정하시겠습니까?')) {
        return
      }
      await this.$axios.post(url, album).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert('정보를 수정하였습니다.', 'success')
          this.getAlbums()
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
      })
    },
    getAlbums () {
      this.$emit('update-albums')
    },
    async updateAlbums () {
      const url = '/manage/style/albums'
      const albumArray = []
      for (let i = 0; i < this.albums.length; i++) {
        const album = this.albums[i]
        if (album === null) {
          this.$alert(`${i + 1}번째 앨범 수정 중 오류가 발생하였습니다. 새로고침 후 재시도 바랍니다.`, 'error')
          return
        }
        if (!album.albumName) {
          this.$alert(`${i + 1}번째 앨범명은 비어있을 수 없습니다.`, 'error')
          return
        }
        if (album.sortOrder === null) {
          this.$alert(`${i + 1}번째 정렬 순서는 비어있을 수 없습니다.`, 'error')
          return
        }
        albumArray.push(album)
      }
      if (!confirm('정보를 수정하시겠습니까?')) {
        return
      }
      await this.$axios.post(url, albumArray).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert('정보를 수정하였습니다.', 'success')
          this.getAlbums()
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
      })
    }
  }
}
</script>

<style scoped>

</style>
