<template>
  <div class="main-router-icon" @click="this.$router.push(link)">
    <v-img
            :src="image"
            position="absolute"
            width="100%"
            cover
            height="100%"
            :lazy-src="image"
    />
    <div class="main-router-overlay"/>
    <div class="main-router-content">
      <h3>
        {{ title }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainRouterIcon',
  props: ['image', 'title', 'link']
}
</script>

<style scoped lang="scss">
.main-router-icon {
  width: 100%;
  height: 100%;
  position: relative;

  .main-router-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.6;
  }

  .main-router-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    box-shadow: inset 0px 0px 20px 15px #00000012;
    font-size: 24px;

    h3 {
      font-family: ghanachoco;
      font-size: 2em;
      opacity: 0.7;
      text-align: center;
    }
  }
}
</style>
