<template>
  <div class="h-100 px-5">
    <v-row class="h-100">
      <v-col cols="12" class="py-0 pt-md-10">
        <v-row v-if="productTypes && productTypes.length > 0">
          <template v-for="item in productTypes" :key="`product-type-${item.idx}`">
            <v-col lg="4" md="4" cols="12" class="position-relative cursor-pointer pa-1"
                   @click="$router.push(item.link)">
              <div class="position-relative product-type-card">
                <div :style="getCardStyle(item)"/>
                <div class="product-type-card-overlay d-flex justify-center align-center">
                  <v-icon v-if="!item.firstProduct" class="empty-icon" :style="{ fontSize: '100px', color: 'white' }">
                    mdi-tray-remove
                  </v-icon>
                </div>
                <div class="w-100 text-white zIndex-1 justify-center d-flex align-center" :style="{ height: '180px' }">
                  <h5 class="ghanachoco" :style="{ fontSize: '24px', fontWeight: 600 }">
                    {{ item.productTypeName }}
                  </h5>
                </div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'ProductsHome',
  data () {
    return {
      productTypes: [],
      productUrl: '/products/1?productTypeIdx='
    }
  },
  beforeMount () {
    this.getProductTypes()
  },
  methods: {
    async getProductTypes () {
      await this.$axios.get('/product/type/list').then(res => {
        if (res.data) {
          this.productTypes = res.data
          for (const p of this.productTypes) {
            p.link = this.productUrl + p.idx
          }
        }
      })
    },
    getCardStyle (productType) {
      const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0'
      }
      if (productType && productType.firstProduct) {
        style.backgroundImage = `url('${productType.firstProduct.imageSrc}')`
        style.backgroundSize = 'cover'
        style.backgroundPosition = 'center'
      } else {
        style.backgroundColor = 'white'
      }
      return style
    }
  }
}
</script>

<style scoped lang="scss">
.product-type-card {
  border-radius: 5px;
  overflow: hidden;

  .product-type-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.5;

    .empty-icon {
      opacity: 0.1;
    }
  }
}

</style>
