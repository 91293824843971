<template>
  <v-menu location="bottom" width="90%">
    <template #activator="{ props }">
      <v-badge v-if="alerts && alerts.list.length > 0" dot color="red" class="mx-3 position-relative"
               :style="{ top: '-2px' }">
        <v-icon v-bind="props">
          mdi-bell-outline
        </v-icon>
      </v-badge>
      <v-icon v-else v-bind="props" class="position-relative" :style="{ top: '-2px' }">
        mdi-bell-outline
      </v-icon>
    </template>
    <v-list class="py-0 bg-white">
      <template v-if="alerts && alerts.list.length > 0">
        <v-list-item
                v-for="(item, i) in alerts.list"
                :key="'alarm-' + i"
                :value="i"
                density="compact"
                class="py-2"
                :style="{
          borderBottom: i + 1 < alerts.list.length ? '1px solid #eee' : ''
        }"
                @click="updateRead(item)"
        >
          <p class="text-12" :class="{ 'text-grey': item.read, 'font-weight-bold': !item.read }"
             style="overflow-x: hidden">
            {{ item.alertContent }}
          </p>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item
                density="compact"
                class="py-2"
        >
          <p class="text-12" style="overflow-x: hidden">
            읽지않은 알림이 존재하지 않습니다.
          </p>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AlarmLayer',
  data () {
    return {
      alerts: {
        list: []
      }
    }
  },
  beforeMount () {
    this.getPrivacyAlerts()
  },
  methods: {
    async getPrivacyAlerts () {
      const params = {
        pageNum: 1,
        pageDataSize: 5,
        read: 'false'
      }
      this.$axios.get('/alert/list', { params: params }).then(res => {
        this.alerts = res.data
      })
    },
    async updateRead (alert) {
      const query = {
        idx: alert.idx
      }
      await this.$axios.post('/alert', query).then(res => {
        if (res.data.status === 'SUCCESS') {
          alert.read = true
        } else {
          this.$alert('알림을 읽음상태로 변경하는데 실패하였습니다.', 'error')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
