<template>
  <div class="h-100 overflow-y-auto overflow-x-hidden no-scroll mb-1">
    <div class="pl-3 py-1 pt-md-10 h-100 pr-3 pr-md-0 d-none d-md-block">
      <v-sheet
              height="100%"
              rounded="lg"
              :style="{
            borderRight: '1px solid #eee'
          }"
              class="no-scroll"
      >
        <v-list rounded="lg" height="100%" class="no-scroll">
          <v-list-item
                  v-for="(item, i) in productTypes"
                  :key="'left-menu-style-' + i"
                  class="nanumgothic text-13"
                  :to="item.link"
          >
            {{ item.productTypeName }}
          </v-list-item>
        </v-list>
      </v-sheet>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsLeftMenu',
  props: ['productTypes'],
  data () {
    return {
      selectedStyle: this.$route.path,
      leftMenus: [
        {
          title: '헤어드라이어',
          link: '/products/1'
        },
        {
          title: '고데기',
          link: '/products/2'
        },
        {
          title: '헤어클리퍼',
          link: '/products/3'
        },
        {
          title: '기타기기',
          link: '/products/4'
        }
      ]
    }
  },
  watch: {
    selectedStyle (link) {
      this.$router.push(link)
    }
  },
  mounted () {
    console.log(this.$route)
  }
}
</script>

<style scoped lang="scss">
.v-list-item--active:deep(.v-list-item__overlay) {
  background-color: transparent;
}

.active-album {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}
</style>
