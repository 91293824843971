<template>
  <div class="bg-grey-lighten-4 h-100 mt-3">
    <v-row justify="center">
      <v-col cols="12" class="pb-1 pt-5">
        <h5 class="text-grey-darken-1 font-weight-light px-4">프로필</h5>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openProfilePopup" persistent width="500" :fullscreen="$isMobile()"
                    transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey text-15 mr-2" size="small">
                    mdi-account-circle
                  </v-icon>
                  <p class="w-50 text-13">
                    프로필 변경
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }">
                <template v-slot:title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <h5 class="text-14 font-weight-medium pr-3 lh-14">
                          프로필변경
                        </h5>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <h5 class="text-14 font-weight-medium pl-3 lh-14">
                          프로필변경
                        </h5>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-slot:content>
                  <v-row class="pb-7">
                    <v-col cols="12" class="text-center">
                      <v-avatar :style="{ width: '100px', height: '100px' }" @click="triggerFileInput">
                        <template #default>
                          <v-img :src="user.profileImage ? user.profileImage : require('@/assets/images/sample/user/no-profile-image.jpg')"
                                 :lazy-src="user.profileImage ? user.profileImage : require('@/assets/images/sample/user/no-profile-image.jpg')"
                                 rounded="lg" class="position-relative">
                            <div class="text-12 position-absolute w-100 text-center"
                                 :style="{ bottom: '0px', backgroundColor: 'grey', color: 'white', paddingBottom: '2px', paddingTop: '2px' }">
                              변경
                            </div>
                          </v-img>
                        </template>
                      </v-avatar>
                      <input
                              ref="fileInput"
                              type="file"
                              accept="image/*"
                              class="d-none"
                              @change="handleFileChange"
                      />
                    </v-col>
                    <v-col cols="12" class="text-12">
                      <form class="profile-form">
                        <div>
                          <p>
                            Username.
                          </p>
                          <input v-model="user.username" type="text" placeholder="Username"
                                 class="w-100 pt-1 pb-2 text-grey-darken-2 text-13 mb-3">
                        </div>
                        <div class="mt-3">
                          <p>
                            Mobile.
                          </p>
                          <input v-model="user.phone" type="text" placeholder="Mobile"
                                 class="w-100 pt-1 pb-2 text-grey-darken-2 text-13 mb-3">
                        </div>
                        <div class="mt-3">
                          <v-btn rounded="0" elevation="0" class="w-100" color="black" @click="saveProfile">
                            저장하기
                          </v-btn>
                        </div>
                      </form>
                    </v-col>
                  </v-row>
                </template>
              </default-layer>
            </template>
          </v-dialog>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="pb-1 pt-3">
        <h5 class="text-grey-darken-1 font-weight-light px-4">보안</h5>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openPasswordPopup" persistent width="500" :fullscreen="$isMobile()"
                    transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey text-15 mr-2" size="small">
                    mdi-lock
                  </v-icon>
                  <p class="w-50 text-13">
                    비밀번호 변경
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }">
                <template v-slot:title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <h5 class="text-14 font-weight-medium pr-3 lh-14">
                          비밀번호변경
                        </h5>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <h5 class="text-14 font-weight-medium pl-3 lh-14">
                          비밀번호변경
                        </h5>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-slot:content>
                  <v-row class="pb-7">
                    <v-col cols="12" class="text-12">
                      <form class="profile-form">
                        <div>
                          <p>
                            Current P.W.
                          </p>
                          <input v-model="passwords.currentPassword" type="password" placeholder="현재 패스워드를 입력해주세요."
                                 class="w-100 pt-1 pb-2 text-grey-darken-2 text-13 mb-3">
                        </div>
                        <div class="mt-3">
                          <p>
                            New P.W.
                          </p>
                          <input v-model="passwords.newPassword" type="password" placeholder="새로운 패스워드를 입력해주세요."
                                 class="w-100 pt-1 pb-2 text-grey-darken-2 text-13 mb-3">
                        </div>
                        <div class="mt-3">
                          <p>
                            New P.W. Confirm.
                          </p>
                          <input v-model="passwords.newPasswordConfirm" type="password"
                                 placeholder="새로운 패스워드를 다시 입력해주세요."
                                 class="w-100 pt-1 pb-2 text-grey-darken-2 text-13 mb-3">
                        </div>
                        <div class="mt-3">
                          <v-btn rounded="0" elevation="0" class="w-100" color="black" @click="updatePassword">
                            비밀번호변경
                          </v-btn>
                        </div>
                      </form>
                    </v-col>
                  </v-row>
                </template>
              </default-layer>
            </template>
          </v-dialog>
          <v-divider color="grey" />
          <v-list-item to="/setting/withdraw">
            <div class="d-flex w-100 align-center">
              <v-icon class="text-grey text-15 mr-2" size="small">
                mdi-close-circle
              </v-icon>
              <p class="w-50 text-13">
                회원탈퇴
              </p>
              <p class="w-50 text-right text-13 text-grey">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </p>
            </div>
          </v-list-item>
          <v-divider color="grey"/>
          <v-list-item @click="logout">
            <div class="d-flex w-100 align-center">
              <v-icon class="text-grey text-15 mr-2" size="small">
                mdi-logout
              </v-icon>
              <p class="w-50 text-13">
                로그아웃
              </p>
              <p class="w-50 text-right text-13 text-grey">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </p>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="pb-1 pt-5">
        <h5 class="text-grey-darken-1 font-weight-light px-4">고객지원</h5>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openNoticePopup" persistent width="500" :fullscreen="$isMobile()"
                    transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey mr-2" size="small">
                    mdi-book-alert-outline
                  </v-icon>
                  <p class="w-50 text-13">
                    공지사항
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }" class="card-text-px-0 card-text-py-0">
                <template #title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <h5 class="text-14 font-weight-medium pr-3 lh-14">
                          공지사항
                        </h5>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <h5 class="text-14 font-weight-medium pl-3 lh-14">
                          공지사항
                        </h5>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template #content>
                  <notice-popup/>
                </template>
              </default-layer>
            </template>
          </v-dialog>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openCsPopup" persistent width="500" :fullscreen="$isMobile()"
                    transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey mr-2" size="small">
                    mdi-headset
                  </v-icon>
                  <p class="w-50 text-13">
                    1:1 문의
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }" class="card-text-px-0 card-text-py-0">
                <template #title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <v-dialog v-model="openCsRegisterPopup" width="600" transition="dialog-bottom-transition"
                                  :fullscreen="$isMobile()">
                          <template #activator="{ props }">
                            <v-btn v-bind="props" class="text-14 font-weight-medium pr-3 lh-14" size="small" flat color="grey-lighten-4">
                              1:1 문의등록
                            </v-btn>
                          </template>
                          <template #default="{ isActive }">
                            <cs-register @update-cs="updateCs" @close-register="isActive.value = false"/>
                          </template>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <v-dialog v-model="openCsRegisterPopup" width="600" transition="dialog-bottom-transition"
                                  :fullscreen="$isMobile()">
                          <template #activator="{ props }">
                            <v-btn v-bind="props" class="text-14 font-weight-medium pr-3 lh-14" size="small" flat color="grey-lighten-4">
                              1:1 문의등록
                            </v-btn>
                          </template>
                          <template #default="{ isActive }">
                            <cs-register @update-cs="updateCs" @close-register="isActive.value = false"/>
                          </template>
                        </v-dialog>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template #content>
                  <cs-popup ref="csPopup"/>
                </template>
              </default-layer>
            </template>
          </v-dialog>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openTermPopup" width="500" :fullscreen="$isMobile()" transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey mr-2" size="small">
                    mdi-checkbook
                  </v-icon>
                  <p class="w-50 text-13">
                    회원약관
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }">
                <template #title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <h5 class="text-14 font-weight-medium pr-3 lh-14">
                          회원약관
                        </h5>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <h5 class="text-14 font-weight-medium pl-3 lh-14">
                          회원약관
                        </h5>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template #content>
                  <div class="text-body-2" v-html="settings ? settings.userTerm : '등록된 회원약관이 존재하지 않습니다.'"/>
                </template>
              </default-layer>
            </template>
          </v-dialog>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-dialog v-model="openPrivacyPopup" width="500" :fullscreen="$isMobile()"
                    transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <div class="d-flex w-100 align-center">
                  <v-icon class="text-grey mr-2" size="small">
                    mdi-account-lock
                  </v-icon>
                  <p class="w-50 text-13">
                    개인정보취급방침
                  </p>
                  <p class="w-50 text-right text-13 text-grey">
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </div>
              </v-list-item>
            </template>
            <template #default="{ isActive }">
              <default-layer :layer-style="{ overflowY: 'hidden' }">
                <template #title>
                  <template v-if="$isMobile()">
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                                flat
                                color="transparent"
                                icon
                                size="small"
                                @click="isActive.value = false"
                        >
                          <v-icon>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <span class="text-14">
                          Settings
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end">
                        <h5 class="text-14 font-weight-medium pr-3 lh-14">
                          개인정보취급방침
                        </h5>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="6" class="d-flex align-center justify-start">
                        <h5 class="text-14 font-weight-medium pl-3 lh-14">
                          개인정보취급방침
                        </h5>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                                flat
                                size="small"
                                @click="isActive.value = false"
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template #content>
                  <div class="text-body-2"
                       v-html="settings ? settings.privacyContent : '등록된 개인정보취급방침이 존재하지 않습니다.'"/>
                </template>
              </default-layer>
            </template>
          </v-dialog>
        </v-list>
      </v-col>
      <v-divider color="grey"/>
    </v-row>
  </div>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'
import NoticePopup from '@/components/board/NoticePopup.vue'
import CsPopup from '@/components/board/CsPopup.vue'
import { mapActions, mapState } from 'vuex'
import CsRegister from '@/components/board/CsRegister.vue'

export default {
  name: 'SettingsView',
  components: {
    CsRegister,
    CsPopup,
    NoticePopup,
    DefaultLayer
  },
  data () {
    return {
      user: {},
      openProfilePopup: false,
      openPasswordPopup: false,
      openNoticePopup: false,
      openCsPopup: false,
      openTermPopup: false,
      openPrivacyPopup: false,
      openCsRegisterPopup: false,
      passwords: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      }
    }
  },
  beforeMount () {
    this.getUser()
  },
  computed: {
    ...mapState('site', ['settings'])
  },
  methods: {
    ...mapActions('auth', ['logout', 'changeProfileImage']),
    updateCs () {
      const csPopup = this.$refs.csPopup
      csPopup.getPagination(1)
    },
    isValidPassword (password) {
      const pattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$/
      return pattern.test(password)
    },
    triggerFileInput () {
      this.$refs.fileInput.click()
    },
    async handleFileChange (event) {
      const formData = new FormData()
      const files = event.target.files
      const image = files[0]
      const maxImageSize = 5 * 1024 * 1024
      if (image.size > maxImageSize) {
        this.$alert('이미지의 크기는 최대 5MB를 초과 할 수 없습니다.', 'warning')
        return
      }
      formData.append('image', image)
      await this.$axios.post('/attachment/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data) {
          this.user.profileImage = `/api/attachment/image/${res.data.url}`
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async updatePassword () {
      if (!this.passwords) {
        alert('패스워드 변경 중 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요.')
        return
      }
      if (!this.passwords.currentPassword) {
        alert('현재 패스워드를 입력해주세요.')
        return
      }
      if (!this.passwords.newPassword) {
        alert('변경 할 패스워드를 입력해주세요.')
        return
      }
      if (!this.passwords.newPasswordConfirm || this.passwords.newPassword !== this.passwords.newPasswordConfirm) {
        alert('변경 할 패스워드가 서로 일치하지 않습니다.')
        return
      }
      if (!this.isValidPassword(this.passwords.newPassword)) {
        console.log(this.passwords.newPassword)
        alert('패스워드는 8자 이상 20자 이하여야 하며, 영문, 숫자, 특수문자가 포함되어야 합니다.')
        return
      }
      if (confirm('패스워드를 변경하시겠습니까?')) {
        await this.$axios.post('/user/password', this.passwords).then(res => {
          if (res.data.status === 'SUCCESS') {
            alert('패스워드가 변경되었습니다.')
            this.openPasswordPopup = false
          } else {
            alert(this.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      }
    },
    async getUser () {
      await this.$axios.get('/user').then(res => {
        this.user = res.data
        this.openProfilePopup = false
      })
    },
    async saveProfile () {
      if (confirm('사용자 프로필 정보를 변경하시겠습니까?')) {
        await this.$axios.post('/user/profile', this.user).then(res => {
          if (res.data.status === 'SUCCESS') {
            alert('사용자 프로필이 변경되었습니다.')
            this.getUser()
            if (this.user.profileImage) {
              this.changeProfileImage(this.user.profileImage)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.profile-form {
  input {
    color: white;
    border-bottom: 1px solid grey;

    &:focus {
      outline: none;
    }
  }
}
</style>
