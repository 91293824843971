import axios from '../../plugins/axios'
export default {
  namespaced: true,
  state: {
    settings: {
    }
  },
  mutations: {
    setSettings: (state, payload) => {
      state.settings = payload
    }
  },
  actions: {
    loadSettings: async ({ commit }) => {
      const url = '/site/settings'
      await axios.get(url).then(res => {
        if (res.data) {
          if (typeof res.data === 'object') {
            commit('setSettings', res.data)
          } else {
            commit('setSettings', {})
          }
        } else {
          commit('setSettings', {})
        }
      }).catch(() => {
        commit('setSettings', {})
      })
    }
  }
}
