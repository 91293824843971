<template>
  <div class="bg-grey-lighten-4 h-100 mt-3">
    <v-row justify="center" class="h-100">
      <v-col md="6" cols="12" class="pb-1 pt-5 h-100 bg-white">
        <h3 class="text-grey-darken-1 font-weight-light px-4">
          회원탈퇴
        </h3>
        <v-divider />
        <div class="text-center py-5">
          <v-icon :style="{ fontSize: '50px' }" class="text-grey-darken-1">
            mdi-check-circle-outline
          </v-icon>
          <div class="mt-3">
            <h4 class="text-decoration-underline">
              VO.HA.B 탈퇴 전 확인해주세요!
            </h4>
          </div>
          <div class="mt-1">
            <p class="text-center text-grey text-13">
              탈퇴하시면 <span class="text-decoration-underline">로그인 및 활동이 즉시 중지</span>되며,<br>모든 데이터는 복구가 불가능합니다.
            </p>
          </div>
          <v-row justify="center" class="ml-3 mb-3 mr-3 mt-2">
            <v-col cols="10" md="7" class="bg-white border pa-3">
              <p class="text-13 text-left">
                · 모든 문의사항 및 답변이 삭제됩니다.
              </p>
              <p class="text-13 text-left">
                · 매니저 권한은 위임 후 탈퇴가 가능합니다.
              </p>
              <p class="text-13 text-left">
                · 계정 정보는 정책에 의해 <span class="font-weight-bold text-decoration-underline">{{ deleteTerm }} 파기</span>됩니다.
              </p>
            </v-col>
            <v-col cols="10" md="7" class="px-0">
              <v-checkbox v-model="agreeWithdraw" hide-details density="compact">
                <template #label>
                  <p class="text-13 pl-2 text-left">안내사항을 모두 확인했으며, 이에 동의합니다.</p>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="10" md="7" class="px-0 pt-0">
              <v-btn width="100%" rounded="0" color="black" flat @click="withdraw">
                탈퇴하기
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'WithdrawView',
  data () {
    return {
      agreeWithdraw: false
    }
  },
  computed: {
    ...mapState('site', ['settings']),
    deleteTerm () {
      const term = this.settings ? this.settings.userWithdrawTerm : 0
      if (term) {
        return `${term}개월 후`
      } else {
        return '즉시'
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async withdraw () {
      if (!this.agreeWithdraw) {
        this.$alert('안내사항에 대하여 동의 후 회원탈퇴가 가능합니다.', 'warning')
        return
      }
      if (confirm('회원탈퇴를 진행하시겠습니까?')) {
        await this.$axios.delete('/user/withdraw').then(res => {
          if (res.data.status === 'SUCCESS') {
            this.logout()
          } else {
            alert(res.data.message || '회원탈퇴 중 오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('회원탈퇴 중 오류가 발생하였습니다.')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
