<template>
  <v-card :style="layerStyle" rounded="0">
    <v-card-title style="padding-left: 0.5rem; padding-right: 0.5rem;">
      <slot name="title"></slot>
    </v-card-title>
    <v-card-text :style="{ maxHeight: '800px', overflowY: 'auto' }" class="no-scroll">
      <slot name="content"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DefaultLayer',
  props: ['layerStyle']
}
</script>

<style lang="scss">
.card-text-px-0 {
  .v-card-text {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.card-text-py-0 {
  .v-card-text {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
