<template>
  <div class="home h-100 mt-3">
    <div :style="{ height: '50px' }" class="d-flex align-end justify-start mb-2">
      <h1 :style="{ fontSize: '18px', color: '#3e3e3e' }">
        VO.HA.B <span class="text-13">Aria Styles</span><br>
      </h1>
      <v-spacer/>
      <h1 :style="{ fontSize: '18px', color: '#3e3e3e' }">
        {{ title }}
      </h1>
    </div>
    <div class="bg-white pa-3 no-scroll">
      <div :style="{ height: '800px', overflow: 'auto', borderRadius: '5px', border: '1px solid #eee', backgroundColor: '#f8f8f8' }" class="pa-15 no-scroll"
           v-html="content"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex'

export default {
  name: 'TermsView',
  computed: {
    ...mapState('site', ['settings']),
    title () {
      if (this.path === '/user-term') {
        return '회원약관'
      } else {
        return '개인정보취급방침'
      }
    },
    content () {
      if (this.path === '/user-term') {
        return this.settings.userTerm
      } else {
        return this.settings.privacyContent
      }
    },
    path () {
      return this.$route.path
    }
  }
}
</script>
