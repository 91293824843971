<template>
  <div class="pa-5 pa-md-10">
    <v-row>
      <v-col
              v-for="(item, i) in manageMenu"
              :key="'manage-menu-' + i"
              cols="12"
              md="6"
              @click="$router.push(item.link)"
      >
        <v-sheet class="d-flex justify-center align-center" :style="item.style">
          <div class="menu-overlay"/>
          <p class="zIndex-1">
            {{ item.name }}
          </p>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ManageHome',
  data () {
    return {
      manageMenu: [
        {
          name: 'Members',
          link: '/manage/members/1',
          style: {
            backgroundImage: 'url(' + require('@/assets/images/background/manage/manageUserImage.jpg') + ')',
            backgroundSize: 'cover',
            position: 'relative',
            color: 'white',
            fontFamily: 'ghanachoco',
            fontSize: '2rem',
            borderRadius: '5px',
            height: '300px'
          }
        },
        {
          name: 'Styles',
          link: '/manage/styles/1',
          style: {
            backgroundImage: 'url(' + require('@/assets/images/background/manage/manageStylesImage.jpg') + ')',
            backgroundSize: 'cover',
            position: 'relative',
            color: 'white',
            fontFamily: 'ghanachoco',
            fontSize: '2rem',
            borderRadius: '1rem',
            height: '300px'
          }
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.menu-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 0;
  border-radius: 5px;
}
</style>
