<template>
  <default-layer class="card-text-px-0 card-text-py-0">
    <template #title>
      <template v-if="$isMobile()">
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <v-btn
                    flat
                    color="transparent"
                    icon="mdi-chevron-left"
                    size="small"
                    @click="close"
            />
            <div class="text-14">
              1:1 문의등록
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-start">
            <h5 class="text-14 font-weight-medium pl-3 lh-14">
              1:1 문의등록
            </h5>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
                    flat
                    size="small"
                    @click="close"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
    <template #content>
      <v-row>
        <v-col cols="12">
          <div class="px-5">
            <v-text-field v-model="cs.title" hide-details variant="underlined" placeholder="제목을 입력해주세요" label="Title"/>
          </div>
        </v-col>
        <v-col cols="12" class="pb-5 pb-md-0">
          <div class="px-5">
            <custom-editor ref="csEditor" height="400px" :content="cs.csContent"/>
          </div>
        </v-col>
      </v-row>
      <div class="px-5 pb-5">
        <v-btn color="black" rounded="0" flat class="w-100" size="large" @click="registerCs">
          문의등록
        </v-btn>
      </div>
    </template>
  </default-layer>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'
import CustomEditor from '@/components/editor/Editor.vue'

export default {
  name: 'CsRegister',
  components: {
    CustomEditor,
    DefaultLayer
  },
  data () {
    return {
      cs: {
        title: '',
        csContent: ''
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    async registerCs () {
      if (!this.cs.title) {
        alert('문의 제목을 입력해주세요.')
        return
      }
      const content = this.$refs.csEditor.getContent()
      if (!content) {
        alert('문의 내용을 입력해주세요.')
        return
      }
      this.cs.csContent = content
      if (!confirm('1:1 문의를 등록하시겠습니까?')) {
        return
      }
      await this.$axios.put('/cs', this.cs).then(res => {
        if (res.data.status === 'SUCCESS') {
          alert('문의사항이 등록되었습니다.')
          this.$emit('update-cs')
          this.close()
        }
      })
    },
    close () {
      this.cs = {}
      this.$emit('close-register')
    }
  }
}
</script>

<style scoped>

</style>
