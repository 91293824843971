<template>
  <div id="signup_wrap">
    <div class="signup-box">
      <div class="signup-box-bg" />
      <div class="input-wrap">
        <form class="signup-form mt-15 mb-5">
          <div>
            <div class="d-flex align-center">
              <v-icon size="small" class="text-grey-darken-2 mr-2">
                mdi-scissors-cutting
              </v-icon>
              <p class="text-14 text-grey-darken-2">회원가입 약관에 동의하십니까?</p>
              <v-dialog v-model="isOpenTerm" width="600" :fullscreen="$isMobile()">
                <template #activator="{ props }">
                  <span v-bind="props" class="text-12 text-grey text-decoration-underline ml-2">내용보기</span>
                </template>
                <v-card>
                  <v-card-title>
                    <h3 class="text-15">
                      회원가입약관
                    </h3>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <div class="text-body-2" v-html="settings ? settings.userTerm : '등록된 개인정보 취급방침이 존재하지 않습니다.'" />
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn class="w-100" flat @click="isOpenTerm = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
              <v-btn-toggle v-model="isAgreeTerm" density="compact">
                <v-btn size="small" class="agree-term" value="true">
                  <v-icon class="mr-1">
                    mdi-check
                  </v-icon>
                  동의
                </v-btn>
              </v-btn-toggle>
            </div>
            <div class="d-flex align-center my-3">
              <v-icon size="small" class="text-grey-darken-2 mr-2">
                mdi-scissors-cutting
              </v-icon>
              <p class="text-14 text-grey-darken-2">개인정보취급방침에 동의하십니까?</p>
              <v-dialog v-model="isOpenPrivacy" width="600" :fullscreen="$isMobile()">
                <template #activator="{ props }">
                  <span v-bind="props" class="text-12 text-grey text-decoration-underline ml-2">내용보기</span>
                </template>
                <v-card>
                  <v-card-title>
                    <h3 class="text-15">
                      개인정보취급방침
                    </h3>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <div class="text-body-2" v-html="settings ? settings.privacyContent : '등록된 개인정보 취급방침이 존재하지 않습니다.'" />
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn class="w-100" flat @click="isOpenPrivacy = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
              <v-btn-toggle v-model="isAgreePrivacy" density="compact">
                <v-btn size="small" class="agree-term" value="true">
                  <v-icon class="mr-1">
                    mdi-check
                  </v-icon>
                  동의
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <input v-model="user.id" type="text" placeholder="ID" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13 mb-3">
          <input v-model="user.password" type="password" placeholder="Password" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13 mb-3" :class="validPassword ? 'password-match' : ''">
          <input v-model="user.passwordConfirm" type="password" placeholder="Password-Confirm" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13 mb-3" :class="user.password && user.passwordConfirm && user.password === user.passwordConfirm ? 'password-match' : ''">
          <input v-model="user.username" type="text" placeholder="Username" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13  mb-3">
          <input v-model="user.uniqueCode" type="text" placeholder="Shop Code" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13  mb-3">
          <input v-model="user.phone" type="text" placeholder="Mobile" class="w-100 pt-3 pb-2 text-grey-darken-2 text-13  mb-3">
        </form>
        <v-btn width="100%" flat rounded="0" color="black" @click="registerUser">
          signup
        </v-btn>
      </div>
      <div class="w-100 d-flex flex-wrap mt-2 action-wrap">
        <div class="w-100 text-center">
          <router-link to="/login">
            <span class="text-13 text-grey">로그인페이지로 돌아가기</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'signUpView',
  data () {
    return {
      isAgreeTerm: false,
      isAgreePrivacy: false,
      isOpenTerm: false,
      isOpenPrivacy: false,
      user: {
        id: '',
        username: '',
        password: '',
        passwordConfirm: '',
        uniqueCode: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapState('site', ['settings']),
    validPassword () {
      return this.isValidPassword(this.user.password)
    }
  },
  methods: {
    async registerUser () {
      if (!this.isValidId(this.user.id)) {
        this.$alert('아이디는 영문 소문자가 하나 이상 포함된, 6자 이상 12자 이하의 영문 소문자와 숫자로만 사용 가능합니다.', 'warning')
        return
      }
      if (!this.isValidUsername(this.user.username)) {
        this.$alert('이름은 2자 이상 12자 이하의 문자열이어야 합니다.', 'warning')
        return
      }
      if (!this.isValidPassword(this.user.password)) {
        this.$alert('패스워드는 8자 이상 20자 이하여야 하며, 영문, 숫자, 특수문자가 포함되어야 합니다.', 'warning')
        return
      }
      if (this.user.password !== this.user.passwordConfirm) {
        this.$alert('패스워드가 서로 다릅니다.', 'warning')
        return
      }
      if (!this.user.phone) {
        this.$alert('전화번호를 입력해주세요.', 'warning')
        return
      }
      if (!this.isAgreeTerm) {
        this.$alert('회원약관에 동의 후 회원가입 진행이 가능합니다.', 'warning')
        return
      }
      if (!this.isAgreePrivacy) {
        this.$alert('개인정보취급방침에 동의 후 회원가입 진행이 가능합니다.', 'warning')
        return
      }
      if (!confirm('회원가입을 진행하시겠습니까?')) {
        return
      }
      await this.$axios.put('/signup', this.user).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$alert('회원가입을 완료하였습니다.', 'success')
          this.$router.push('/login')
        } else {
          this.$alert(res.data.message || '오류가 발생하였습니다.', 'error')
        }
      }).catch(() => {
        this.$alert('오류가 발생하였습니다.', 'error')
      })
    },
    isValidId (id) {
      const pattern = /^(?=.*[a-z])[a-z0-9]{6,12}$/
      return pattern.test(id)
    },
    isValidUsername (username) {
      const pattern = /^(?!.*[\s_()]{2,})[a-zA-Z가-힣0-9_() ]{2,12}$/
      return pattern.test(username)
    },
    isValidPassword (password) {
      const pattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$/
      return pattern.test(password)
    }
  }
}
</script>

<style scoped lang="scss">
#signup_wrap {
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  .signup-box {
    max-width: 400px;
    width: 95%;
    top: 50px;
    position: relative;
    padding: 10px;
    .signup-box-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .input-wrap {
      position: relative;
      display: flex;
      z-index: 1;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      .v-input::v-deep .v-field__input {
        padding-bottom: 10px;
      }
      .signup-form {
        width: 100%;
        .agree-term {
          background-color: #eee;
        }
        .v-btn--active {
          background-color: #000;
          color: white;
          .v-icon {
            color: greenyellow;
            font-weight: bold;
          }
        }
        input {
          color: white;
          border-bottom: 1px solid grey;
          &.password-match {
            border-bottom: 1px solid limegreen;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .action-wrap {
      z-index: 1;
      position: relative;
    }
  }
}

</style>
