<template>
  <v-row justify="center">
    <v-col lg="4" md="6" sm="8" cols="12" class="py-0">
      <terms-view/>
    </v-col>
  </v-row>
</template>

<script>

import TermsView from '@/views/auth/TermsView.vue'

export default {
  name: 'LoginLayout',
  components: { TermsView },
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
</style>
