<template>
  <div class="h-100 overflow-y-auto overflow-x-hidden no-scroll mb-1">
    <div class="pl-3 py-1 pt-md-10 h-100 pr-3 pr-md-0 d-none d-md-block">
      <v-sheet
              height="100%"
              rounded="lg"
              :style="{
            borderRight: '1px solid #eee'
          }"
      >
        <v-list rounded="lg" height="100%" class="no-scroll">
          <v-list-item
                  v-for="(item, i) in albums"
                  :key="'left-menu-style-' + i"
                  class="nanumgothic text-13"
                  :class="{ 'active-album' : isActive(item.idx) }"
                  :to="item.link"
          >
            <span>
              {{ item.albumName }}
            </span>
          </v-list-item>
        </v-list>
      </v-sheet>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StylesLeftMenu',
  props: ['albums'],
  data () {
    return {
      selectedAlbum: null
    }
  },
  watch: {
    selectedStyle (link) {
      this.$router.push(link)
    }
  },
  computed: {
    albumIdx () {
      return this.$route.query ? this.$route.query.albumIdx : ''
    }
  },
  methods: {
    isActive (idx) {
      return this.$route.query.albumIdx === idx.toString()
    }
  }
}
</script>

<style scoped lang="scss">
.v-list-item--active:deep(.v-list-item__overlay) {
  background-color: transparent;
}

.active-album {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}
</style>
