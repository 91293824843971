<template>
  <div id="bottom-nav-bar" class="d-flex align-center" :style="{ height: $isMobile() ? '70px' : '70px' }">
    <div class="text-center w-20">
      <router-link to="/home" class="text-grey-darken-3" :class="$isMobile() ? 'text-13' : ''">
        Home
      </router-link>
    </div>
    <div class="text-center w-20">
      <router-link to="/styles/1" class="text-grey-darken-3" :class="$isMobile() ? 'text-13' : ''">
        Styles
      </router-link>
    </div>
    <div class="text-center w-20">
      <router-link to="/community/1" class="text-grey-darken-3" :class="$isMobile() ? 'text-13' : ''">
        Community
      </router-link>
    </div>
    <div class="text-center w-20">
      <router-link to="/products/1" class="text-grey-darken-3" :class="$isMobile() ? 'text-13' : ''">
        Products
      </router-link>
    </div>
    <div class="text-center w-20">
      <router-link to="/setting" class="text-grey-darken-3" :class="$isMobile() ? 'text-13' : ''">
        Settings
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomNavBar'
}
</script>

<style scoped lang="scss">
#bottom-nav-bar {
  height: 70px;
}
.w-20 {
  width: 20%;
}
</style>
<script setup>
</script>
